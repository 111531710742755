import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { BaseTable } from 'ui-components';
import { getConfiguredAlertsForPatient, unpublishPatientAlert } from '../api/alertsApi';
import { Mixpanel } from '../mixpanel';
import { getToken } from '../utils/authenticator';
import { getAlertDataById, prepConfiguredAlertsData, prepDataForEditAlertModal } from '../utils/pageOperations/patientPageOperations';
import { AlertConfirmModal } from 'ui-components';
import EditAlertModalContainer from './EditAlertModalContainer';

const useStyles = makeStyles((theme) => ({
    subNavContainer: {
        paddingTop: 14,
        paddingBottom: 16
    },
    tableContainer: {
        paddingTop: 8
    }
}));

export default function ConfiguredAlertsTableContainer(props) {
    const classes = useStyles();

    const {
        patientId,
        refetchAlerts,
        openToast,
        conditionType
    } = props;

    const [data, setData] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [editModalOpen, setEditModalOpen] = React.useState(false);
    const [selectedAlertId, setSelectedAlertId] = React.useState(null);
    const [selectedAlert, setSelectedAlert] = React.useState({});
    const [refetchAlertsLocal, setRefetchAlertsLocal] = React.useState(0);

    const configuredAlertsColumns = [
        {label: 'Type', id: 'type', width: 152},
        {label: 'Trigger', id: 'trigger', width: 144},
        {label: 'Time frame', id: 'timeFrame', width: 144},
        {label: 'Email', id: 'email', width: 88},
        {label: 'SMS', id: 'sms', width: 88},
        {label: 'Call', id: 'call', width: 80},
        {label: 'Creation date', id: 'creationDate', width: 168},
    ];

    const configuredAlertsColumnsExtraStyles = {
        'table-column-type': {
            maxWidth: 152,
            minWidth: 152,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-column-trigger': {
            maxWidth: 144,
            minWidth: 144,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-column-timeFrame': {
            maxWidth: 144,
            minWidth: 144,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-column-email': {
            maxWidth: 88,
            minWidth: 88,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-column-sms': {
            maxWidth: 88,
            minWidth: 88,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-column-call': {
            maxWidth: 80,
            minWidth: 80,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-column-creationDate': {
            maxWidth: 168,
            minWidth: 168,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-column-actions': {
            maxWidth: 40,
            minWidth: 40,
            paddingLeft: 8,
            paddingRight: 8,
        },
        'table-cell-type': {
            maxWidth: 152,
            minWidth: 152,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-cell-trigger': {
            maxWidth: 144,
            minWidth: 144,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-cell-timeFrame': {
            maxWidth: 144,
            minWidth: 144,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-cell-email': {
            maxWidth: 88,
            minWidth: 88,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-cell-sms': {
            maxWidth: 88,
            minWidth: 88,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-cell-call': {
            maxWidth: 80,
            minWidth: 80,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-cell-creationDate': {
            maxWidth: 168,
            minWidth: 168,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-cell-actions': {
            maxWidth: 40,
            minWidth: 40,
            paddingLeft: 8,
            paddingRight: 8,
        },
    };

    const handleChangeRowsPerPage = () => {
        Mixpanel.track('Configured alerts table: rows per page');
    };

    const handleMenuItemClick = (action, row, id) => {
        Mixpanel.track(`Alerts Tab: ${action} Alert Modal`);
        setSelectedAlertId(id);
        tableData.forEach((element) => {
            if(element.id === id) {
                setSelectedAlert(element);
            }
        });

        if(action === 'delete') {
            setDeleteModalOpen(true);
        };

        if(action === 'edit') {
            setEditModalOpen(true);
        };
    };

    const handleMenuClick = (col, row, id) => {
        Mixpanel.track('Configured Alerts Table: actions menu');
    };

    const handleUnpublishAlert = async (id) => {
        Mixpanel.track('Delete Alert Modal: Confirm');
        const response = await unpublishPatientAlert(id, getToken());
        if(response.status === 200) {
            setDeleteModalOpen(false);
            setSelectedAlert({});
            setSelectedAlertId(null);
            openToast('delete');
            setRefetchAlertsLocal(refetchAlertsLocal + 1);
        } else {
            openToast('error');
        }
    };

    const handleEditAlert = async (status) => {
        if(status === 200) {
            setEditModalOpen(false)
            setSelectedAlert({});
            setSelectedAlertId(null);
            openToast('edit');
            setRefetchAlertsLocal(refetchAlertsLocal + 1);
        } else {
            openToast('error');
        }
    }
    const handleCloseUnpublishAlert = () => {
        Mixpanel.track('Delete Alert Modal: Delete Alert Modal close');
        setDeleteModalOpen(false);
        setSelectedAlert({});
        setSelectedAlertId(null);
    };

    const handleCancelUnpublishAlert = () => {
        Mixpanel.track('Delete Alert Modal: Delete Alert Modal cancel');
        setDeleteModalOpen(false);
        setSelectedAlert({});
        setSelectedAlertId(null);
    };

    useEffect(() => {
        Mixpanel.track('Opened Configured Alerts Tab');
    }, []);

    useEffect(() => {
        const getConfiguredAlertsForPatientFromApi = async () => {
            setLoading(true);
            const result = await getConfiguredAlertsForPatient(patientId, getToken());

            setData(result);
            setTableData(prepConfiguredAlertsData(result));
            setLoading(false);
        };
        
        getConfiguredAlertsForPatientFromApi();
    }, [patientId, refetchAlerts, refetchAlertsLocal]);

    return (
        <>
            <Grid container>
                <Grid item xs={12} className={classes.tableContainer}>
                    {
                        loading
                        ? null
                        : <BaseTable
                            columns={configuredAlertsColumns}
                            data={tableData}
                            colouredRows
                            pagination
                            rowsPerPage={20}
                            rowsPerPageOptions={[20, 40, 80]}
                            actions={[{label: 'edit', key: 'edit'},{label: 'delete', key: 'delete'}]}
                            onMenuClick={handleMenuClick}
                            onMenuItemClick={handleMenuItemClick}
                            extraStyles={configuredAlertsColumnsExtraStyles}
                            sorting={false}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    }
                </Grid>
            </Grid>
            <AlertConfirmModal
                onSubmit={handleUnpublishAlert}
                onCancel={handleCancelUnpublishAlert}
                onClose={handleCloseUnpublishAlert}
                open={deleteModalOpen}
                values={[
                    {
                        label: 'Measurement type',
                        value: selectedAlert.type ? selectedAlert.type.label || '-' : '-'
                    },
                    {
                        label: 'Trigger',
                        value: selectedAlert.trigger ? selectedAlert.trigger.label || '-' : '-'
                    },
                    {
                        label: 'Time frame',
                        value: selectedAlert.timeFrame ? selectedAlert.timeFrame.label || '-' : '-'
                    },
                    {
                        label: 'Creation date',
                        value: selectedAlert.creationDate ? selectedAlert.creationDate.label || '-' : '-'
                    },
                    {
                        label: 'Summary',
                        value: selectedAlert.summary ? selectedAlert.summary : '-'
                    },
                ]}
                id={selectedAlertId}
                mode='delete'
            />
            <EditAlertModalContainer 
                {...(selectedAlertId && {initialFieldValues : prepDataForEditAlertModal(getAlertDataById(data, selectedAlertId))})}
                open={editModalOpen}
                patientId={patientId}
                handleClose={()=>{
                    setEditModalOpen(false)
                    setSelectedAlert({});
                    setSelectedAlertId(null);
                }}
                handleCancel={()=>{
                    setEditModalOpen(false)
                    setSelectedAlert({});
                    setSelectedAlertId(null);
                }}
                onSubmit={handleEditAlert}
                conditionType={conditionType}
            />
        </>
    )
}
