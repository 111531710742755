import React, { useEffect } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { StatisticCardGroup } from 'ui-components';
import { getPatientFilterSummaries, getPatientFilterSummariesForHospital } from '../api/conditionsApi';
import { prepVitalsData, prepHospitalVitalsData } from '../utils/pageOperations/dashboardPageOperations';
import { getToken } from '../utils/authenticator';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    loadingLabel: {
        height: "120px",
        marginLeft: "10px",
        width: "75%",
        marginBottom: "60px"
    },
});

export default function VitalsSummaryCardsContainer(props) {
    const classes = useStyles();
    const [vitalsSummary, setVitalsSummary] = React.useState([]);
    const [vitalsLoading, setVitalsLoading] = React.useState(true);

    useEffect(() => {
        let isMounted = true; // flag for component mount status
        const getPatientFilterSummariesForHospitalFromApi = async (hospitalId) => {
            setVitalsLoading(true);
            const result = await getPatientFilterSummariesForHospital(hospitalId, getToken());
            const vitals = await prepHospitalVitalsData(result, hospitalId);
            if (isMounted){ // Only run if this component is still mounted
                setVitalsSummary(vitals);
                setVitalsLoading(false);
            }
        }

        const getPatientFilterSummariesFromApi = async () => {
            setVitalsLoading(true);
            const result = await getPatientFilterSummaries(getToken());
            const vitals = await prepVitalsData(result);
            if (isMounted){ // Only run if this component is still mounted
                setVitalsSummary(vitals);
                setVitalsLoading(false);
            }
        }

        if(props.hospital === 'all') {
            getPatientFilterSummariesFromApi();
        } else {
            getPatientFilterSummariesForHospitalFromApi(props.hospital)
        }
        return function cleanup() {
            isMounted = false; // function called when the component is unmounted
        };
    }, [props.hospital]);

    return (
        vitalsLoading
            ? <Skeleton className={classes.loadingLabel}/>
            : <StatisticCardGroup title="Vitals Summary" data={vitalsSummary} />
    )
}
