import React, { useEffect } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { StatisticCardGroup } from 'ui-components';
import { getPatientStatusSummary, getPatientStatusSummaryForHospital } from '../api/patientsApi';
import { prepStatusData } from '../utils/pageOperations/dashboardPageOperations';
import { getToken } from '../utils/authenticator';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    loadingLabel: {
        height: "120px",
        marginLeft: "10px",
        width: "75%",
        marginBottom: "60px"
    },
});

export default function StatusSummaryCardsContainer(props) {
    const classes = useStyles();
    const [statusSummary, setStatusSummary] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        let isMounted = true; // flag for component mount status

        const getPatientStatusSummariesFromApi = async () => {
            const result = await getPatientStatusSummary(getToken());
            if (isMounted){ // Only run if this component is still mounted
                setStatusSummary(prepStatusData(result));
                setLoading(false);
            }
        }

        const getPatientStatusSummariesForHospitalFromApi = async (hospitalId) => {
            const result = await getPatientStatusSummaryForHospital(getToken(), hospitalId);
            if (isMounted){ // Only run if this component is still mounted
                setStatusSummary(prepStatusData(result));
                setLoading(false);
            }
        }

        if(props.hospital === 'all') {
            getPatientStatusSummariesFromApi();
        } else {
            getPatientStatusSummariesForHospitalFromApi(props.hospital)
        }

        return function cleanup() {
            isMounted = false; // function called when the component is unmounted
        };
    }, [props.hospital]);

    return (
        loading
            ? <Skeleton className={classes.loadingLabel}/>
            : <StatisticCardGroup title="Live Metrics" data={statusSummary}/>
    )
}
