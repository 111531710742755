import { cmsGetRequest, cmsPostRequest, cmsPutRequest } from '../utils/cmsRequests';

const endpoint = process.env.REACT_APP_CMS_BASE_URL;
const headers = new Headers();
headers.append("Content-Type", "application/json");

export const getTriggeredAlertsForPatient = async (id, token) => {
    const response = await cmsGetRequest(`${endpoint}api/triggered-measurement-alerts/?patient_id=${id}`, token);
    let responseJson;
    if(response.status === 200) {
        responseJson = await response.json();
    } else {
        responseJson = []
    }
    
    return responseJson;
};

export const resolveTriggeredAlert = async (id, token) => {
    const raw = JSON.stringify({});

    const response = await cmsPostRequest(`${endpoint}api/triggered-measurement-alerts/${id}/resolve/`, raw, token);

    return response;
};

export const createPatientAlert = async (alert, token) => {
    const raw = JSON.stringify(alert);

    const response = await cmsPostRequest(`${endpoint}api/measurement-alerts/`, raw, token);

    return response;
};

export const unpublishPatientAlert = async (alertId, token) => {
    const raw = JSON.stringify({});

    const response = await cmsPostRequest(`${endpoint}api/measurement-alerts/${alertId}/unpublish/`, raw, token);

    return response;
};

export const getAlertsMetadata = async (token) => {
    const response = await cmsGetRequest(`${endpoint}api/measurement-alerts/metadata/`, token);
    let responseJson;
    if(response.status === 200) {
        responseJson = await response.json();
    } else {
        responseJson = [];
    }

    return responseJson;
};

export const getConfiguredAlertsForPatient = async (id, token) => {
    const response = await cmsGetRequest(`${endpoint}api/measurement-alerts/?patient_id=${id}`, token);
    let responseJson;
    if(response.status === 200) {
        responseJson = await response.json();
    } else {
        responseJson = [];
    }

    return responseJson;
};

export const getUnresolvedAlerts = async (id, token) => {
    const response = await cmsGetRequest(`${endpoint}api/triggered-measurement-alerts/unresolved-count/?patient_id=${id}`, token);
    let responseJson;
    if(response.status === 200) {
        responseJson = await response.json();
    } else {
        responseJson = []
    }
    
    return responseJson;
};

export const editPatientAlert = async (id, alert, token) => {
    const raw = JSON.stringify(alert);

    const response = await cmsPutRequest(`${endpoint}api/measurement-alerts/${id}/`, raw, token);

    return response;
};
