import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Mixpanel } from '../mixpanel';

const useStyles = makeStyles({
    hide: {
        display: 'none'
    }
});

export default function GenerateHospitalReportButtonContainer(props) {
    const classes = useStyles();

    const handleReportOpen = () => {
        Mixpanel.track('Clicked Generate Report button (Dashboard)');
        props.handleHospitalReportOpen(true);
    }

    return (
        <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleReportOpen} 
            className={props.hospitals.length >= 1 ? '' : classes.hide}
            disabled={props.hospital.id === 'all' && props.hospitals.length > 1 ? true : false }
        >
            Generate Report
        </Button>
    )
}