import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import DataAreaContainer from '../containers/DataAreaContainer';
import moment from 'moment';
import { Mixpanel } from '../mixpanel';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LatestMeasurementsContainer from '../containers/LatestMeasurementsContainer';
import AddMeasurementButtonContainer from '../containers/AddMeasurementButtonContainer';
import DateRangeSelectorContainer from '../containers/DateRangeSelectorContainer';
import AddMeasurementDialogContainer from '../containers/AddMeasurementDialogContainer';
import QuickDateRangeButtonContainer from '../containers/QuickDateRangeButtonContainer';
import SpirometryDataContainer from '../containers/SpirometryDataContainer';
import { isSpirometryCondition } from '../utils/pageOperations/patientPageOperations';
import { getMeasurementsForConditionType } from '../utils/conditionTypeMeasurementConfig';

const useStyles = makeStyles({
    background: {
        backgroundColor: '#f3f5fb',
        alignItems: 'center',
    },
    gridContainer: {
        paddingTop: 24,
        paddingBottom: 24
    },
    header: {
        paddingTop: 24,
    },
    addMeasurement: {
        paddingTop: 4,
    }, 
    dataAreaHeader: {
        paddingTop: 24,
        paddingBottom: 8
    },
    measurementTitle: {
        paddingBottom: 15
    }
});

export default function VitalsTabContainer (props) {
    const classes = useStyles();

    const { conditionType, patientId } = props; 

    const [startDate, setStartDate] = React.useState(moment().subtract(2, 'weeks'));
    const [endDate, setEndDate] = React.useState(moment());
    const [measurementsAdded, setMeasurementsAdded] = React.useState(0);
    const [measurements, setMeasurements] = React.useState([]);
    const [currentMethod, setCurrentMethod] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [addMeasurementOpen, setAddMeasurementOpen] = React.useState(false);

    const handleMeasurementChange = event => {
        Mixpanel.track('Changing measurement type');
        setCurrentMethod(event);
    };
    
    const handleDateChange = (newStartDate, newEndDate) => {
        Mixpanel.track('Changing date range');
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const handleStartDateChange = (newStartDate) => {
        Mixpanel.track('Changing date range');
        setStartDate(newStartDate);
    };

    const handleEndDateChange = (newEndDate) => {
        Mixpanel.track('Changing date range');
        setEndDate(newEndDate);
    };

    const handleAddMeasurementOpen = (result) => {
        setAddMeasurementOpen(result);
    }

    const handleAddMeasurementChange = () => {
        setMeasurementsAdded(measurementsAdded + 1);
        setMeasurements(measurements);
    };

    useEffect(() => {
        if(conditionType) {
            setMeasurements(getMeasurementsForConditionType(conditionType));
            setCurrentMethod(getMeasurementsForConditionType(conditionType)[0]);
            setLoading(false);
        };
    }, [conditionType]);

    return (
        <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>
                {
                    loading
                    ? ""
                    : <LatestMeasurementsContainer
                        patientId={patientId}
                        onMeasurementChange={handleMeasurementChange}
                        measurementsAdded={measurementsAdded}
                        measurements={measurements}
                    />
                }
            </Grid>
            <Grid item xs={6} className={classes.dataAreaHeader}>
                <Grid item xs={12} className={classes.measurementTitle}>
                    <Typography variant='h4'>{loading ? null : currentMethod.label}</Typography>
                </Grid>
                { currentMethod.canAddMeasurement
                    ?   <Grid item xs={12} className={classes.addMeasurement}>
                            <AddMeasurementButtonContainer
                                handleAddMeasurementOpen={handleAddMeasurementOpen}
                            />
                            <AddMeasurementDialogContainer
                                patientId={patientId}
                                currentMethod={currentMethod}
                                onChange={handleAddMeasurementChange}
                                open={addMeasurementOpen}
                                handleAddMeasurementOpen={handleAddMeasurementOpen}
                                conditionLoading={loading}
                                conditionType={conditionType}
                            />
                        </Grid>
                    : null
                }
            </Grid>
            <Grid item xs={6} className={classes.dataAreaHeader} align="right">
                <Grid item xs={12} align="right">
                    <DateRangeSelectorContainer
                        startDate={startDate}
                        endDate={endDate}
                        handleStartDateChange={handleStartDateChange}
                        handleEndDateChange={handleEndDateChange}
                    />
                </Grid>
                <Grid item xs={12} align="right">
                    <QuickDateRangeButtonContainer
                        handleClick={handleDateChange}
                        amount={7}
                        type={'days'}
                    />
                    <QuickDateRangeButtonContainer
                        handleClick={handleDateChange}
                        amount={30}
                        type={'days'}
                    />
                    <QuickDateRangeButtonContainer
                        handleClick={handleDateChange}
                        amount={3}
                        type={'months'}
                    />
                </Grid>
            </Grid>
            <DataAreaContainer
                conditionLoading={loading}
                measurementType={currentMethod}
                patientId={patientId}
                startDate={startDate}
                endDate={endDate}
                measurementsAdded={measurementsAdded}
            />
            {
                isSpirometryCondition(conditionType) 
                ? <SpirometryDataContainer 
                    patientId={patientId}
                />
                : null
            }
        </Grid>
    )
}
