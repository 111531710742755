import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { LogInCard } from 'ui-components';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import { login } from '../api/accountsApi';
import { Mixpanel } from '../mixpanel';
import BackgroundImage from '../static/img/LoginBackground.png';
import { getToken } from '../utils/authenticator';
import CELogo from '../static/img/ce-mark-white.png';
import { Redirect } from 'react-router-dom';
import { getLoggedInHospitalStaff } from '../api/hospitalApi';

const useStyles = makeStyles({
    loginPage: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
    },
    contentContainer: {
        display: 'flex',
        flex: 7,
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardsContainer: {
        display: 'block',
        maxWidth: 450,
    },
    loginCard: {
        marginBottom: 16,
    },
    infoCard: {
        display: 'block',
        maxWidth: 450,
        marginBottom: 16,
    },
    footer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
    }
});

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export default function LoginPage(props) {
    let query = new useQuery();
    const nextLocation = query.get("next") ? query.get("next") : "/patients";

    const classes = useStyles();

    const [token, setToken] = React.useState("");
    const [loggedIn, setLoggedIn] = React.useState(false);

    const getLoggedInHospitalStaffFromApi = async () => {
        const result = await getLoggedInHospitalStaff(getToken());
        Mixpanel.identify(result.id);
        if(result.hospital){
            Mixpanel.people.set({'$hospital': result.hospital.id})
        }
    }

    const handleSubmit = async (username, password) => {
        const result = await login(username, password);
        if(result.status === 200){
            await getLoggedInHospitalStaffFromApi();
            setLoggedIn(true);
        } else {
            alert("Incorrect credentials, try again");
        }
    };

    useEffect(() => { 
        setToken(getToken() || "");
    }, []);

    useEffect(() => {
        if(token.length > 0) {
            setLoggedIn(true);
        }
    }, [token])

    return (
        <React.Fragment>
            <div className={classes.loginPage}>{loggedIn 
                    ? <Redirect
                        to={nextLocation}
                    /> 
                    : null
                }
                <div className={classes.contentContainer}>
                    <Grid container className={classes.cardsContainer}>
                        <Grid item xs={12} className={classes.loginCard}>
                            <LogInCard 
                                handleSubmit={handleSubmit}
                                forgotPasswordLink={`${process.env.REACT_APP_CMS_BASE_URL}accounts/password/reset`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant='body2'>
                                    System Use Notification (US Users): Please note use of this information system may be 
                                    monitored, recorded, and subject to audit. Unauthorized use of this information system 
                                    is prohibited and may result in penalties.  Use of this information system indicates 
                                    consent to monitoring and recording. For users located in the EU your rights are 
                                    covered by GDPR.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
                <Grid container className={classes.footer}>
                    <img src={CELogo} alt="CE logo" height="30" width="30"/>
                </Grid>
            </div>
        </React.Fragment>
    )
} 
