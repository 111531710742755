import React, { useEffect } from 'react';
import { TableFilter } from 'ui-components';
import { getPatientFilters } from '../api/patientsApi';
import { getToken } from '../utils/authenticator';

export default function TableFilterContainer(props) {

    const [filters, setFilters] = React.useState([]);
    const [loadingFilter, setLoadingFilter] = React.useState(true);

    useEffect(() => {
        let isMounted = true; // flag for component mount status
        const getAllPatientFilters = async () => {
            const result = await getPatientFilters(getToken());
            if (isMounted){ // Only run if this component is still mounted
                setFilters(result);
                setLoadingFilter(false);
            }
        } 

        getAllPatientFilters();

        return function cleanup() {
            isMounted = false; // function called when the component is unmounted
        };
    }, []);

    return (
        <TableFilter
            onClose={props.handleChangeFilter}
            filters={filters}
            loading={loadingFilter}
        />
    )
}