import { useState } from 'react';

/**
 * 
 * @param {object} intitialFieldValues - The initial values of a form
 * @param {boolean} validateOnChange - (Optional) If true, the validate function is called on every input change
 * @param {function} validate - (Optional) Function in charge of validate form values
 * @param {function} customUpdateValues - (Optional) Function in charge of any custom functionality needed when form values change
 */
export default function useForm(intitialFieldValues, validateOnChange=false, validate, customUpdateValues) {

    // make a deep clone of initialFieldValues before assigning it to values
    const [values, setValues] = useState(JSON.parse(JSON.stringify(intitialFieldValues)));
    const [errors, setErrors] = useState({});

    /**
     * 
     * @param {string} name - The name of the input field in the form. e.g measurementField for a choosing a measurement type 
     * @param {string} value - The value within the input field
     * @param {array} subGroup - Array of values within a certain input component. E.g for checkbox group
     */
    const handleInputChange = (e) => {
        const { name, value, subGroup } = e.target;
        if(subGroup) {
            setValues({
                ...values,
                [subGroup]:{
                    ...values[subGroup],
                    [name]:value
                }
            });
        } else {
            setValues({
                ...values,
                [name]:value
            });
        }

        if(customUpdateValues) {
            customUpdateValues(name, value);
        }

        if(validateOnChange) {
            validate({[name]:value});
        }
    };

    const resetForm = () => {
        setValues(intitialFieldValues);
        setErrors({});
    };

    return {
        values,
        setValues,
        handleInputChange,
        errors,
        setErrors,
        resetForm
    };
}
