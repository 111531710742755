import { cmsGetRequest } from '../utils/cmsRequests';

const endpoint = process.env.REACT_APP_CMS_BASE_URL;

export const getConditionStatusSummaries = async (id, token) => {
    const result = await cmsGetRequest(`${endpoint}api/condition_type/${id}/status_summaries`, token);
    return result.json();
}

export const getConditionTypes = async (token) => {
    const result = await cmsGetRequest(`${endpoint}api/condition_type/`, token);
    return result.json();
}

export const getPatientFilterSummaries = async (token) => {
    const result = await cmsGetRequest(`${endpoint}monitoring-portal/api/patient_filter/summaries/`, token);
    return result.json();
}

export const getPatientFilterSummariesForHospital = async (hospitalId, token) => {
    const result = await cmsGetRequest(`${endpoint}monitoring-portal/api/patient_filter/summaries?hospital_id=${hospitalId}`, token);
    return result.json();
}