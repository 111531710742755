import React, { useEffect } from 'react';
import { BaseTable } from 'ui-components';
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { editPatientReview, getPatientReviewEvents, unpublishPatientReview } from '../api/patientsApi';
import { prepReviewData } from '../utils/pageOperations/patientPageOperations';
import { Mixpanel } from '../mixpanel';
import { getToken } from '../utils/authenticator';
import DeleteReviewModal from 'ui-components/dist/DeleteReviewModal/DeleteReviewModal';
import EditReviewModal from 'ui-components/dist/EditReviewModal/EditReviewModal';

const useStyles = makeStyles({
    gridContainer: {
        paddingBottom: 24,
    },
    buttonContainer: {
        paddingTop: 16
    },
    displayingTitle: {
        opacity: 0.87,
        color: '#000000',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '24px',
    },
    displayingContainer: {
        paddingBottom: 16
    }
});


/* 
This object gets passed into the BaseTable to give more custom styles to the rows and cells
The extra styles follow the convention 'table-[cell or column]-[<column id>]'
IE - table-cell-notes will apply its stylings to the all cells in the column with the id of 'notes'
*/
const extraStyles = {
    'table-column-date': {
        maxWidth: 152,
        minWidth: 152,
        paddingRight: 40,
    },
    'table-column-reviewedByName': {
        maxWidth: 152,
        minWidth: 152,
        paddingRight: 40,
    },
    'table-cell-reviewedByName-content': {
        maxWidth: 112,
    },
    'table-column-notes': {
        maxWidth: 568,
        minWidth: 517,
        paddingRight: 51,
    },
    'table-cell-date': {
        maxWidth: 152,
        minWidth: 152,
        paddingRight: 40,
    },
    'table-cell-reviewedByName': {
        maxWidth: 152,
        minWidth: 152,
        paddingRight: 40,
    },
    'table-cell-notes': {
        maxWidth: 568,
        minWidth: 568,
        paddingRight: 51,
    }
};

export default function ReviewsTableContainer(props) {
    const classes = useStyles();

    const columns = [
        {label: 'Date Entered', id: 'date', width: 152, sortAs: 'date'},
        {label: 'Reviewed By', width: 152, id: 'reviewedByName'},
        {label: 'Notes', id: 'notes', width: 600,},
    ];

    const {
        patientId, 
        user, 
        reviewed,
        refreshReviews,
        updateReviewed,
        openToast,
        onChangeRowsPerPage,
    } = props;

    const [reviewData, setReviewData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [editModalOpen, setEditModalOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage);
    const [page, setPage] = React.useState(0);

    const handleDelete = async (id) => {
        Mixpanel.track('Confirm Delete Review Button clicked');
        const response = await unpublishPatientReview(getToken(), id);
        if (response.status === 200) {
            updateReviewed();
            refreshReviews();
            openToast('delete');
            setDeleteModalOpen(false);
        } else {
            openToast('error');
        }
    };

    const handleEdit = async (id, newNotes) => {
        Mixpanel.track('Confirm Edit Review Button clicked');
        const response = await editPatientReview(getToken(), id, newNotes, patientId);
        if(response.status === 200) {
            updateReviewed();
            refreshReviews();
            openToast('edit');
            setEditModalOpen(false);
        } else {
            openToast('error');
        }
    };

    const handleCancelDelete = () => {
        Mixpanel.track('Cancel Delete Review clicked');
    };

    const handleCancelEdit = () => {
        Mixpanel.track('Cancel Edit Review clicked');
    };

    const handleMenuClick = () => {
        Mixpanel.track('Review Menu Button clicked');
    };

    const getSelectedRow = (rowId) => {
        let newSelectedRow;
        reviewData.forEach((element) => {
            if(element.id === rowId) {
                newSelectedRow = element;
            }
        });
        return newSelectedRow;
    }

    const getActionsForTable = () => {
        if(process.env.REACT_APP_PORTAL_TYPE === 'COVID-19') {
            return [];
        }

        return [{label: 'edit', key: 'edit'}, {label: 'delete', key: 'delete'}];
    };
    
    const handleMenuItemClick = (action, row, id) => {
        Mixpanel.track(`Clicked '${action}' menu item in review row`);
        setSelectedRow(getSelectedRow(id));
        if(action === 'delete') {
            setDeleteModalOpen(true);
        } else if(action === 'edit') {
            setEditModalOpen(true);
        }
    };
    
    const handleToggleReadMore = () => {
        Mixpanel.track('Read more toggled');
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        Mixpanel.track('Changed rows per page on reviews table');
        onChangeRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
        Mixpanel.track('Changed page on reviews table');
    }

    useEffect(() => {
        const getPatientReviewEventsFromApi = async () => {
            setLoading(true);
            const result = await getPatientReviewEvents(patientId, getToken());
    
            setReviewData(prepReviewData(result, user));
            setLoading(false);
        }

        if(user){
            getPatientReviewEventsFromApi();
        }
    }, [patientId, reviewed, user]);

    return (
        <Grid container className={classes.gridContainer}>
            <Grid item xs={12} className={classes.tableContainer}>
                {loading
                    ? null
                    : <BaseTable 
                        columns={columns}
                        data={reviewData}
                        colouredRows={true}
                        location={'Reviews'}
                        clickable={false}
                        sorting={false}
                        pagination={true}
                        actions={getActionsForTable()}
                        onMenuClick={handleMenuClick}
                        onMenuItemClick={handleMenuItemClick}
                        actionsColumnWidth={null}
                        onToggleReadMore={handleToggleReadMore}
                        readMoreColumn={'notes'}
                        readMore={3}
                        search={false}
                        extraStyles={extraStyles}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[8,16,24]}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        onChangePage={handleChangePage}
                        page={page}
                    />
                }
            </Grid>
            {selectedRow
                ? <>
                    <DeleteReviewModal
                        open={deleteModalOpen}
                        onClose={()=>{setDeleteModalOpen(false)}}
                        handleCancelClicked={handleCancelDelete}
                        onConfirm={()=>{handleDelete(selectedRow.id)}}
                        notes={selectedRow.notes.label}
                        date={selectedRow.date.label}
                        reviewedByName={selectedRow.reviewedByName.label}
                    />
                    <EditReviewModal
                        open={editModalOpen}
                        onClose={()=>{setEditModalOpen(false)}}
                        handleCancelClicked={handleCancelEdit}
                        onConfirm={(newNotes)=>{handleEdit(selectedRow.id, newNotes)}}
                        notes={selectedRow.notes.label}
                        date={selectedRow.date.label}
                        reviewedByName={selectedRow.reviewedByName.label}
                    />
                </>
                : null
            }
        </Grid>
    )
}

ReviewsTableContainer.propTypes = {
    date: PropTypes.func,
}
