import React, { useEffect } from 'react';
import AlertForm from 'ui-components/dist/AlertForm/AlertForm';
import { createPatientAlert, getAlertsMetadata } from '../api/alertsApi';
import { getToken } from '../utils/authenticator';
import { getMeasurementsForConditionType } from '../utils/conditionTypeMeasurementConfig';
import { prepMeasurementFields, getUnitForMeasurementType } from '../utils/pageOperations/patientPageOperations';
import useForm from '../hooks/useForm';
import { Mixpanel } from '../mixpanel';

const timeframesAndAggregators = {
    '0': 'Any new measurement reading',
    '1': 'The highest measurement reading in a day',
    '3': 'Consecutive highest measurement readings over 3 days',
    '7': 'Consecutive highest measurement readings over 7 days'
};

const comparators = {
    '>':'greater than ( > )',
    '>=':'greater than or equal to ( >= )',
    '<':'less than ( < )',
    '<=':'less than or equal to ( <= )',
    '%<':'percentage decrease from set value',
    '%>':'percentage increase from set value'
};

export default function CreateAlertModalContainer(props) {
    const {
        open,
        patientId,
        handleClose,
        handleCancel,
        conditionType,
        onSubmit
    } = props;

    const initalFieldValues = ({
        id: patientId,
        measurementField: '',
        comparator: '',
        percentChange: '',
        baseline: '',
        threshold: '',
        timeframeDays: '',
        alertVia: {
            send_email: {
                id: 1,
                label: 'Email',
                value: false
            }, 
            send_sms: {
                id: 2,
                label: 'SMS',
                value: false
            }, 
            send_voice_call: {
                id: 3,
                label: 'Phone',
                value: false
            }
        }
    });

    const validate = (fieldValues = values) => {
        let temp = {...errors};
        if('percentChange' in fieldValues && ['%<', '%>'].includes(values.comparator)) {
            temp.percentChange = fieldValues.percentChange ? '' : 'This field is required';
        }
        if('baseline' in fieldValues && ['%<', '%>'].includes(values.comparator)) {
            temp.baseline = fieldValues.baseline ? '' : 'This field is required';
        }
        if('measurementField' in fieldValues) {
            temp.measurementField = fieldValues.measurementField ? '' : 'This field is required';   
        }
        if('comparator' in fieldValues) {
            temp.comparator = fieldValues.comparator ? '' : 'This field is required';
        }
        if('timeframeDays' in fieldValues) {
            temp.timeframeDays = fieldValues.timeframeDays ? '' : 'This field is required';
        }
        if('threshold' in fieldValues && ['<', '<=', '=>', '>'].includes(values.comparator)) {
            temp.threshold = fieldValues.threshold ? '' : 'This field is required';
        }

        setErrors({
            ...temp
        });

        if(fieldValues === values) {
            return Object.values(temp).every((tempValue) => tempValue === '');
        }
    };

    const updateValues = (name, value) => {
        switch(name) {
            case 'measurementField':
                Mixpanel.track('Create Alert Modal: Changed Measurement type');
                break;
            case 'comparator':
                Mixpanel.track('Create Alert Modal: Changed Operator Type');
                break;
            case 'percentChange':
                Mixpanel.track('Create Alert Modal: Changed Percentage Value');
                break;
            case 'baseline':
                Mixpanel.track('Create Alert Modal: Changed Baseline Value');
                break;
            case 'timeframeDays':
                Mixpanel.track('Create Alert Modal: Changed Time frame');
                break;
            case 'alertVia':
                Mixpanel.track('Create Alert Modal: Changed Alert Destination Checkboxes');
                break;
            default:
                break;
        }

        if(name === 'measurementField') {
            getNewUnit(value);
            setValues(
                {
                    ...initalFieldValues,
                    measurementField: value
                }
            );
            setStage(2);
        } else if(name === 'comparator') {
            setValues({
                ...initalFieldValues,
                id: patientId,
                measurementField: values.measurementField,
                comparator: value
            });
            setStage(3);
        }
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initalFieldValues, true, validate, updateValues);

    const [stage, setStage] = React.useState(1);
    const [measurements, setMeasurements] = React.useState([]);
    const [currentMethod, setCurrentMethod] = React.useState({});
    const [measurementFields, setMeasurementFields] = React.useState({});
    const [submitDisabled, setSubmitDisabled] = React.useState(true);
    const [backendErrors, setBackendErrors] = React.useState({});

    useEffect(() => {
        if(conditionType){
            setMeasurements(getMeasurementsForConditionType(conditionType));
            setCurrentMethod(getMeasurementsForConditionType(conditionType)[0]);
        }
    }, [conditionType]);

    useEffect(() => {
        const getAlertsMetadataFromApi = async () => {
            const metadata = await getAlertsMetadata(getToken());

            setMeasurementFields(prepMeasurementFields(metadata, measurements));
        }

        if(measurements && measurements.length >= 1) {
            getAlertsMetadataFromApi();
        }
    }, [measurements])

    const getNewUnit = (newUnit) => {
        measurements.forEach((element) => {
            if(element.key === newUnit) {
                setCurrentMethod(element);        
            }
        });
    };

    const prepAlertForApi = (values) => {
        const getComparatorForApi = (comparator) => {
            if(['<','>','<=','>='].includes(comparator)) {
                return(comparator);
            }
    
            if(comparator === '%>') {
                return('>');
            }
    
            if(comparator === '%<') {
                return('<');
            }
        };
    
        const getTimeframeDaysForApi = (timeframe) => {
            if(timeframe === '0') {
                return(null);
            }
    
            return parseFloat(timeframe);
        };
    
        const getAggregatorForApi = (timeframe) => {
            if(timeframe === '0') {
                return(null);
            }
    
            return 'MAX';
        };

        let measurementField= values.measurementField;
        let baseline= parseFloat(values.baseline);
        let percentChange = parseFloat(values.percentChange);
        let comparator = getComparatorForApi(values.comparator);
        let timeframeDays = getTimeframeDaysForApi(values.timeframeDays);
        let threshold = parseFloat(values.threshold);
        let aggregator = getAggregatorForApi(values.timeframeDays);
    
        const makeName = () => {
            return(`Highest or max ${timeframeDays} days ${measurementField} ${comparator} ${percentChange ? `${percentChange} of baseline ${baseline}` : `${threshold}`}`);
        };

        const prepTriggerObjects = () => {
            const triggerObjects = {
                measurement_field: measurementField,
                comparator: comparator,
                timeframe_days: timeframeDays
            };

            if(threshold) {
                triggerObjects.threshold = threshold;
            }

            if(baseline) {
                triggerObjects.baseline = baseline
            }

            if(percentChange) {
                triggerObjects.percent_change = percentChange;
            }

            if(aggregator) {
                triggerObjects.aggregator = aggregator;
            }

            return triggerObjects;
        }

        let alert = {
            name: makeName(),
            patient: parseInt(values.id),
            send_email: values.alertVia.send_email.value,
            send_sms: values.alertVia.send_sms.value,
            send_voice_call: values.alertVia.send_voice_call.value,
            active: true,
            trigger_objects: [
                prepTriggerObjects()
            ]
        };

        return(alert);
    };

    const handleSubmit = async (values) => {
        Mixpanel.track('Create Alert Modal: Confirm');
        if(validate()){
            const response = await createPatientAlert(prepAlertForApi(values), getToken());

            if(response.status === 400) {
                const responseJson = await response.json();
                setBackendErrors(responseJson);
            }

            if(response.status === 201) {
                resetForm();
                setStage(1);
                setBackendErrors({});
            }


            onSubmit(response.status);
        }
    };

    useEffect(() => {
        const checkSumbitDisabled = () => {
            if(values.measurementField) {
                if(values.comparator && ['<', '>', '<=', '>='].includes(values.comparator)) {
                    if(values.threshold && values.timeframeDays) {
                        return(false);
                    }
                } else if (values.comparator && ['%<', '%>'].includes(values.comparator)) {
                    if(values.percentChange && values.baseline && values.timeframeDays) {
                        return(false);
                    }
                }
            }
            return(true);
        }
        setSubmitDisabled(checkSumbitDisabled());
    }, [values]);

    return (
        <AlertForm
            open={open}
            values={values}
            stage={stage}
            comparisonMode=''
            measurementFields={measurementFields}
            timeframesAndAggregators={timeframesAndAggregators}
            comparators={comparators}
            unit={getUnitForMeasurementType(currentMethod)}
            submitDisabled={submitDisabled}
            onSubmit={handleSubmit}
            onClose={()=>{
                handleClose();
                setStage(1);
                setBackendErrors({});
            }}
            onCancel={() => {
                handleCancel();
                setStage(1);
                setBackendErrors({});
            }}
            backendErrors={backendErrors}
            handleInputChange={handleInputChange}
            resetForm={resetForm}
            errors={errors}
        />
    )
}
