import React from 'react';
import { Mixpanel } from '../mixpanel';
import { getToken } from '../utils/authenticator';
import { postLatestReviewEvent } from '../api/patientsApi';
import { CreateReviewModal } from 'ui-components';

export default function ReviewPatientDialogContainer(props) {
    const handleCancel = () => {
        Mixpanel.track('Clicked cancel on create review');
    }
    
    const handleClose = () => {
        props.handleReviewPatientOpen(false)
    };

    const onCheckPatient = async (reviewNotes) => {
        Mixpanel.track('Submitted patient review');
        const response = await postLatestReviewEvent(props.patientId, reviewNotes, getToken());
        if(response.status === 201){
            props.handleReviewed(true);
            handleClose();
        } else {
            props.handleReviewed(false);
        }
    }

    return (
        <CreateReviewModal
            open={props.open}
            handleSubmit={onCheckPatient}
            handleClickCancel={handleCancel}
            onClose={handleClose}
        />
    )
}
