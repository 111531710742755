import React from 'react';
import { Mixpanel } from '../mixpanel';
import { 
    Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

export default function ReviewPatientButtonContainer(props) {
    const handleClickOpen = () => {
        props.handleReviewPatientOpen(true);
        Mixpanel.track('Clicked Create New Review button');
    };

    return (
        <Button 
            variant="contained" 
            onClick={handleClickOpen} 
            color="primary"
            startIcon={<AddIcon />}
        >
            Create New Review
        </Button>
    )
}