import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Mixpanel } from '../mixpanel';
import ReviewPatientButtonContainer from './ReviewPatientButtonContainer';
import ReviewPatientDialogContainer from './ReviewPatientDialogContainer';
import Toast from 'ui-components/dist/Toast/Toast';
import ReviewsTableContainer from './ReviewsTableContainer';

const useStyles = makeStyles({
    gridContainer: {
        paddingBottom: 24,
    },
    buttonContainer: {
        paddingTop: 16,
        paddingBottom: 16
    }
});

export default function ReviewsTabContainer (props) {
    const classes = useStyles();

    const { patientId, refreshReviews, onChangeRowsPerPage, rowsPerPage, user } = props;

    const [reviewPatientOpen, setReviewPatientOpen] = React.useState(false);
    const [reviewed, setReviewed] = React.useState(0);
    const [toastOpen, setToastOpen] = React.useState(false);
    const [deletedToastOpen, setDeletedToastOpen] = React.useState(false);
    const [editedToastOpen, setEditedToastOpen] = React.useState(false);
    const [errorToastOpen, setErrorToastOpen] = React.useState(false);

    useEffect(()=>{
        Mixpanel.track('Opened Reviews Tab');
    },[])

    const handleReviewPatientOpen = (result) => {
        setReviewPatientOpen(result);
    }

    const handleReviewed = (result) => {
        Mixpanel.track('Create Review Button clicked');
        if(result === true) {
            setReviewed(reviewed + 1);
            refreshReviews();
            openToast('create');
        } else {
            setReviewed(reviewed + 1);
            refreshReviews();
            openToast('error');
        }
    };

    const updateReviewed = () => {
        setReviewed(reviewed + 1);
    }

    const openToast = (toast) => {
        switch(toast) {
            case 'create':
                setToastOpen(true);
                break;
            case 'delete':
                setDeletedToastOpen(true);
                break;
            case 'edit':
                setEditedToastOpen(true);
                break;
            case 'error':
                setErrorToastOpen(true);
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Grid container className={classes.buttonContainer}>
                <Grid item xs={12} align="right">
                    <ReviewPatientButtonContainer
                        handleReviewPatientOpen={handleReviewPatientOpen}
                        reviewed={reviewed}
                    />
                    <ReviewPatientDialogContainer
                        patientId={patientId}
                        open={reviewPatientOpen}
                        handleReviewPatientOpen={handleReviewPatientOpen}
                        handleReviewed={handleReviewed}
                    />
                </Grid>
            </Grid>
            <ReviewsTableContainer
                patientId={patientId}
                user={user.id}
                reviewed={reviewed}
                refreshReviews={refreshReviews}
                updateReviewed={updateReviewed}
                openToast={openToast}
                onChangeRowsPerPage={onChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
            />
            <Toast open={toastOpen} onClose={() => {setToastOpen(false)}} message='Review added.'/>
            <Toast open={deletedToastOpen} onClose={() => {setDeletedToastOpen(false)}} message='Review deleted.'/>
            <Toast open={editedToastOpen} onClose={() => {setEditedToastOpen(false)}} message='Review edited.'/>
            <Toast open={errorToastOpen} onClose={() => {setErrorToastOpen(false)}} message='Something went wrong.' error/>
        </>
    )
}
