import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { postPatientMeasurement } from "../api/patientsApi";
import ls from "local-storage";
import moment from "moment";
import NumberFormat from "react-number-format";
import { getToken } from "../utils/authenticator";
import {
  convertToCelsius,
  convertToKg,
  convertToLPerSecond,
  convertToMmol,
} from "../utils/unitOperations";
import {
  isAddMeasurementSubmitDisabled,
  needsSecondaryMeasurement,
} from "../utils/pageOperations/patientPageOperations";

const useStyles = makeStyles({
  measurementGrid: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  measurementTextField: {
    margin: 0,
  },
});

export default function AddMeasurementDialogContainer(props) {
  const classes = useStyles();
  const [newMeasurement, setNewMeasurement] = React.useState(null);
  const [newSecondaryMeasurement, setNewSecondaryMeasurement] =
    React.useState(null);
  const [measurementDate, setMeasurementDate] = React.useState(
    moment().format()
  );

  const handleAddMeasurementClose = () => {
    setNewMeasurement(null);
    setNewSecondaryMeasurement(null);
    props.handleAddMeasurementOpen(false);
  };

  const onSubmitNewMeasurement = async () => {
    let measurement = null;
    if (props.currentMethod.key === "temperature") {
      measurement = convertToCelsius(
        newMeasurement,
        ls.get("temperature_unit")
      );
    } else if (
      props.currentMethod.key === "weight" &&
      newSecondaryMeasurement
    ) {
      measurement = convertToKg(
        { stone: newMeasurement, lbs: newSecondaryMeasurement },
        "st"
      );
    } else if (props.currentMethod.key === "weight") {
      measurement = convertToKg(newMeasurement, ls.get("weight_unit"));
    } else if (props.currentMethod.key === "blood_glucose") {
      measurement = convertToMmol(newMeasurement, ls.get("blood_glucose_unit"));
    } else if (props.currentMethod.key === "pef") {
      measurement = convertToLPerSecond(
        newMeasurement,
        ls.get("flow_rate_unit")
      );
    } else {
      measurement = newMeasurement;
    }

    const response = await postPatientMeasurement(
      props.patientId,
      props.currentMethod.key,
      measurement,
      measurementDate,
      props.conditionType,
      getToken()
    );
    if (!response.ok) {
      const jsonResponse = await response.json();
      alert(`Error: ${jsonResponse[props.currentMethod.key]}`);
    } else {
      props.onChange();
      handleAddMeasurementClose();
    }
    setMeasurementDate(moment().format());
  };

  const prepInputs = () => {
    if (
      props.currentMethod.key === "weight" &&
      ls.get("weight_unit") === "st"
    ) {
      return (
        <Grid container>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              id="primaryMeasurement"
              label="Stone"
              onChange={(e) => setNewMeasurement(e.target.value)}
              InputProps={{ inputComponent: NumberFormat }}
              className={classes.measurementTextField}
            />
          </Grid>
          <Grid item xs={6} className={classes.measurementGrid}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                value={measurementDate}
                onChange={setMeasurementDate}
                disableFuture
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              id="secondaryMeasurement"
              label="Lbs"
              onChange={(e) => setNewSecondaryMeasurement(e.target.value)}
              InputProps={{ inputComponent: NumberFormat }}
              className={classes.measurementTextField}
            />
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container>
          <Grid item xs={6} className={classes.measurementInputContainer}>
            <TextField
              autoFocus
              margin="dense"
              id="primaryMeasurement"
              label={props.currentMethod.label}
              onChange={(e) => setNewMeasurement(e.target.value)}
              InputProps={{ inputComponent: NumberFormat }}
              className={classes.measurementTextField}
            />
          </Grid>
          <Grid item xs={6} className={classes.measurementGrid}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                value={measurementDate}
                onChange={setMeasurementDate}
                disableFuture
                format="MMM DD hh:mm a"
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      );
    }
  };

  const renderCurrentMethodLabel = (currentMethodLabel) => {
    const selectedUnit = ls.get("flow_rate_unit");

    if (currentMethodLabel === "PEF") {
      if (selectedUnit === "L/min") {
        return `PEF (L/min)`;
      } else {
        return `PEF (L/s)`;
      }
    }

    return currentMethodLabel;
  };

  return !props.conditionLoading ? (
    <Dialog
      open={props.open}
      onClose={handleAddMeasurementClose}
      aria-labelledby="add-measurement-dialog"
    >
      <DialogTitle id="form-dialog-title">Add Measurement</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Add a new measurement for{" "}
          {renderCurrentMethodLabel(props.currentMethod.label)} here:
        </DialogContentText>
        {prepInputs()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddMeasurementClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={onSubmitNewMeasurement}
          disabled={isAddMeasurementSubmitDisabled(
            newMeasurement,
            newSecondaryMeasurement,
            needsSecondaryMeasurement(props.currentMethod.key)
          )}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
