import mixpanel from 'mixpanel-browser';

const dev_check = ['development', 'test'].includes(process.env.NODE_ENV);
if(!dev_check) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
}

const actions = {
  identify: (id) => {
    if (!dev_check) {
      mixpanel.identify(id)
    };
  },
  alias: (id) => {
    if (!dev_check) {
      mixpanel.alias(id)
    }
  },
  track: (name, props) => {
    if(!dev_check){
      mixpanel.track(name, props);
    }
  },
  people: {
    set: (props) => {
      if (!dev_check) mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;
