import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { BaseTable, AlertConfirmModal } from 'ui-components';
import { getTriggeredAlertsForPatient, resolveTriggeredAlert } from '../api/alertsApi';
import { getToken } from '../utils/authenticator';
import { prepTriggeredAlertsData } from '../utils/pageOperations/patientPageOperations';
import { Mixpanel } from '../mixpanel';

const useStyles = makeStyles((theme) => ({
    subNavContainer: {
        paddingTop: 14,
        paddingBottom: 16
    },
    tableContainer: {
        paddingTop: 8
    }
}));

export default function TriggeredAlertsTableContainer(props) {
    const classes = useStyles();

    const {
        patientId,
        openToast,
        onChangeResolvedAlerts
    } = props;

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [resolveAlertModalOpen, setResolveAlertModalOpen] = React.useState(false);
    const [selectedAlertId, setSelectedAlertId] = React.useState(null);
    const [selectedAlert, setSelectedAlert] = React.useState({});

    const triggeredAlertsColumns = [
        {label: 'Type', id: 'type', width: 104},
        {label: 'Trigger', id: 'trigger', width: 104},
        {label: 'Time frame', id: 'timeFrame', width: 120},
        {label: 'Time of alert', id: 'timeOfAlert', width: 152},
        {label: 'Alert status', id: 'alertStatus', width: 106},
        {label: 'Resolved by', id: 'resolvedBy', width: 204},
        {label: '', id: 'resolve', width: 114}
    ];

    const triggeredAlertsColumnsExtraStyles = {
        'table-column-type': {
            maxWidth: 104,
            minWidth: 104,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-column-trigger': {
            maxWidth: 104,
            minWidth: 104,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-column-timeFrame': {
            maxWidth: 120,
            minWidth: 120,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-column-timeOfAlert': {
            maxWidth: 152,
            minWidth: 152,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-column-alertStatus': {
            maxWidth: 108,
            minWidth: 108,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-column-resolvedBy': {
            maxWidth: 209,
            minWidth: 209,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-column-resolve': {
            maxWidth: 109,
            minWidth: 109,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-cell-type': {
            maxWidth: 104,
            minWidth: 104,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-cell-trigger': {
            maxWidth: 104,
            minWidth: 104,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-cell-timeFrame': {
            maxWidth: 120,
            minWidth: 120,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-cell-timeOfAlert': {
            maxWidth: 152,
            minWidth: 152,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-cell-alertStatus': {
            maxWidth: 108,
            minWidth: 108,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-cell-resolvedBy': {
            maxWidth: 209,
            minWidth: 209,
            paddingLeft: 8,
            paddingRight: 16,
        },
        'table-cell-resolve': {
            maxWidth: 109,
            minWidth: 109,
            paddingLeft: 8,
            paddingRight: 16,
        },
    };

    useEffect(() => {
        Mixpanel.track('Opened Trigggered Alerts Tab');
    }, []);

    const handleButtonClick = (col, row, id) => {
        Mixpanel.track('Triggered Alert: Resolve Alert');
        setSelectedAlertId(id);
        data.forEach((element) => {
            if(element.id === id){
                setSelectedAlert(element);
            }
        });
        setResolveAlertModalOpen(true);
    };

    const handleResolveAlert = async (id) => {
        Mixpanel.track('Resolve Alert Modal: Resolve Alert Confirm');
        const response = await resolveTriggeredAlert(id, getToken());
        if(response.status === 200) {
            setResolveAlertModalOpen(false);
            setSelectedAlert({});
            setSelectedAlertId(null);
            openToast('resolve');
            onChangeResolvedAlerts();
        } else {
            openToast('error');
        }
    };

    const handleCloseResolveAlert = () => {
        Mixpanel.track('Resolve Alert Modal: Resolve Alert Close');
        setResolveAlertModalOpen(false);
    };

    const handleCancelResolveAlert = () => {
        Mixpanel.track('Resolve Alert Modal: Resolve Alert Cancel');
        setResolveAlertModalOpen(false);
    };

    const handleChangeRowsPerPage = () => {
        Mixpanel.track('Triggered Alerts Table: rows per page change');
    }

    useEffect(() => {
        const getTriggeredAlertsForPatientFromApi = async () => {
            setLoading(true);
            const result = await getTriggeredAlertsForPatient(patientId, getToken());

            setData(prepTriggeredAlertsData(result));
            setLoading(false);
        };
        
        if(!selectedAlertId) {
            getTriggeredAlertsForPatientFromApi();
        }
    }, [patientId, selectedAlertId]);

    return (
        <>
            <Grid container>
                <Grid item xs={12} className={classes.tableContainer}>
                    {
                        loading
                        ? null
                        : <BaseTable
                            columns={triggeredAlertsColumns}
                            data={data}
                            colouredRows
                            pagination
                            rowsPerPage={20}
                            rowsPerPageOptions={[20, 40, 80]}
                            actions={[]}
                            onButtonClick={handleButtonClick}
                            extraStyles={triggeredAlertsColumnsExtraStyles}
                            sorting={false}
                            emptyTableMessage="No alerts have been triggered yet"
                            emptyMessagePosition="center"
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    }
                </Grid>
            </Grid>
            <AlertConfirmModal
                open={resolveAlertModalOpen}
                onSubmit={handleResolveAlert}
                onCancel={handleCancelResolveAlert}
                onClose={handleCloseResolveAlert}
                values={[
                    {
                        label: 'Measurment type',
                        value: selectedAlert.type ? selectedAlert.type.label || '-' : '-'
                    },
                    {
                        label: 'Trigger',
                        value: selectedAlert.trigger ? selectedAlert.trigger.label || '-' : '-'
                    },
                    {
                        label: 'Time frame',
                        value: selectedAlert.timeFrame ? selectedAlert.timeFrame.label || '-' : '-'
                    },
                    {
                        label: 'Time of alert',
                        value: selectedAlert.timeOfAlert ? selectedAlert.timeOfAlert.label || '-' : '-'
                    },
                ]}
                id={selectedAlertId}
            />
        </>
    )
}