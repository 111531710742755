import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Mixpanel } from '../mixpanel';

const useStyles = makeStyles({
    buttonContainer: {
        padding: 0,
        paddingTop: 24,
        paddingBottom: 24
    }
});

export default function NavigateBetweenPatientsContainer(props) {
    const classes = useStyles();
    const onClickPreviousPage = () => {
        Mixpanel.track('Previous Patient button clicked');
        props.onChangePage({
            data: props.sortedTableData.data,
            index: props.sortedTableData.index - 1,
            length: props.sortedTableData.length
        });
    } 

    const onClickNextPage = () => {
        Mixpanel.track('Next Patient button clicked');
        props.onChangePage({
            data: props.sortedTableData.data,
            index: props.sortedTableData.index + 1,
            length: props.sortedTableData.length
        });
    }

    return (
        <>
        <Grid item xs={6} className={classes.buttonContainer}>
            <Button 
                variant="contained"
                color="primary"
                size="small"
                startIcon={<NavigateBeforeIcon />}
                onClick={onClickPreviousPage}
                disabled={props.sortedTableData.index <= 0 ? true: false}
            >
                Previous Patient
            </Button>
        </Grid>
        <Grid item xs={6} align="right" className={classes.buttonContainer}>
            <Button 
                variant="contained"
                color="primary"
                size="small"
                endIcon={<NavigateNextIcon />}
                onClick={onClickNextPage}
                disabled={props.sortedTableData.index >= props.sortedTableData.length - 1 ? true: false}
            >
                Next Patient
            </Button>
        </Grid>
        </>
    )
}