import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UserProfileLabelContainer from '../containers/UserProfileLabelContainer';
import StatusSummaryCardsContainer from '../containers/StatusSummaryCardsContainer';
import VitalsSummaryCardsContainer from '../containers/VitalsSummaryCardsContainer';
import HospitalSelectContainer from '../containers/HospitalSelectContainer';
import GenerateHospitalReportButtonContainer from '../containers/GenerateHospitalReportButtonContainer';
import GenerateHospitalReportDialogContainer from '../containers/GenerateHospitalReportDialogContainer';
import { useIntercom } from 'react-use-intercom';

const useStyles = makeStyles({
    background: {
        backgroundColor: '#f3f5fb',
        alignItems: 'center',
        height: '100vh'
    },
    gridContainer: {
        maxWidth: 960,
        margin: 'auto',
        paddingLeft: 72,
    },
    header: {
        paddingTop: 24,
    },
});

export default function DashboardPage(props) {
    const classes = useStyles();

    const { user } = props;

    const [hospitals, setHospitals] = React.useState([]);
    const [currentHospital, setCurrentHospital] = React.useState({id: 'all'});
    const [hospitalReportOpen, setHospitalReportOpen] = React.useState(false);
    const { update } = useIntercom();
 
    const handleChangeHospital = (id) => {
        if(id === 'all') {
            setCurrentHospital({id: 'all'});
        } else {
            setCurrentHospital({id: id});
        }
    }

    const onSetHospitals = (result) => {
        setHospitals(result);
    }

    const handleHospitalReportOpen = (result) => {
        setHospitalReportOpen(result);
    };

    useEffect(() => {
        if(process.env.REACT_APP_INTERCOM_APP_ID){
            const intercomUpdateWithProps = ((result) => update({
                userId: result.user,
                last_request_at: parseInt((new Date()).getTime()/1000)
            }));

            
            intercomUpdateWithProps(user);
        }
    },[update, user]);

    return (
        <div className={classes.background}>
            <Grid container className={classes.header}></Grid>
            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={12}>
                    <UserProfileLabelContainer />
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="h3">Dashboard</Typography>
                </Grid>
                <Grid item xs={3}>
                    <GenerateHospitalReportButtonContainer
                        hospital={currentHospital}
                        hospitals={hospitals}
                        handleHospitalReportOpen={handleHospitalReportOpen}
                    />
                    <GenerateHospitalReportDialogContainer
                        hospital={currentHospital}
                        open={hospitalReportOpen}
                        handleHospitalReportOpen={handleHospitalReportOpen}
                    />
                </Grid>
                <Grid item xs={12}>
                    <HospitalSelectContainer 
                        handleChangeHospital={handleChangeHospital}
                        updateHospitals={onSetHospitals}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StatusSummaryCardsContainer
                        hospital={currentHospital.id}
                    />
                </Grid>
                <Grid item xs={12}>
                    <VitalsSummaryCardsContainer
                        hospital={currentHospital.id}
                    />
                </Grid>
            </Grid>
        </div>
    )
}
