import React from 'react';
import { getToken } from '../utils/authenticator';
import moment from 'moment';
import { getPatientReport } from '../api/patientsApi';
import { GenerateReportDialog } from 'ui-components';

export default function GeneratePatientReportDialogContainer(props) {
    const [reportStartDate, setReportStartDate] = React.useState(moment().subtract(2, 'weeks'));
    const [reportEndDate, setReportEndDate] = React.useState(moment());

    const handleReportClose = () => {
        props.handleGenerateReportOpen(false);
    }

    const onSumbitGetReport = async () => {
        await getPatientReport(
            getToken(),
            reportStartDate.format('YYYY-MM-DD'),
            reportEndDate.format('YYYY-MM-DD'),
            props.patientId,
            props.archived,
        );
        setReportStartDate(moment().subtract(2, 'weeks'));
        setReportEndDate(moment());
        handleReportClose();
    }

    return (
        <GenerateReportDialog
            open={props.open}
            handleReportClose={handleReportClose}
            reportStartDate={reportStartDate}
            reportEndDate={reportEndDate}
            setReportStartDate={(date)=>{setReportStartDate(date)}}
            setReportEndDate={(date)=>{setReportEndDate(date)}}
            onSumbitGetReport={()=>{onSumbitGetReport()}}
            helperText={'To generate a report for this patient, select a date range below:'}
        />
    )
}