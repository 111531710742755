import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { Mixpanel } from '../mixpanel';

const useStyles = makeStyles({
    button: {
        marginBottom: 10,
    }
});

export default function GeneratePatientReportButtonContainer(props) {
    const classes = useStyles();

    const handleReportOpen = () => {
        Mixpanel.track('Clicked Generate Report button (Patient Page)');
        props.handleGenerateReportOpen(true);
    }

    return (
        <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleReportOpen} 
            className={classes.button}
            startIcon={<EqualizerIcon />}
        >
            Generate Report
        </Button>
    )
}