const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
};

const setCookie = (cname, cvalue, exmins) => {
    var d = new Date();
    d.setTime(d.getTime() + (exmins * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

const removeCookie = (cname) => {
    var d = new Date(0);
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=;" + expires + ";path=/";
}

 
export const getToken = () => {
    const token = getCookie('userToken');
    if(token !== ""){
        setCookie('userToken', token, 30)
    }
    return token;
}

export const removeToken = () => {
    removeCookie('userToken');
}

export const setToken = (token, value) => {
    setCookie(token, value, 30)
}