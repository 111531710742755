import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DetailsCard } from 'ui-components';
import { getPatient } from '../api/patientsApi';
import { getToken } from '../utils/authenticator';
import { prepPatientDetails } from '../utils/pageOperations/patientPageOperations';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles({
    loading: {
        height: '400px',
        marginLeft: '10px',
        width: '90%',
        marginBottom: '30px'
    }
});

export default function PatientDetailsContainer(props) {
    const classes = useStyles();
    const [patientDetails, setPatientDetails] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        const getPatientFromApi = async () => {
            const result = await getPatient(props.patientId, getToken(), props.archived);
            if (isMounted){  // Only run if this component is still mounted
                setPatientDetails(prepPatientDetails(result));
                setLoading(false);
            }
        }
        getPatientFromApi();

        return function cleanup() {
            isMounted = false; // function called when the component is unmounted
        };
    }, [props.patientId, props.archived, props.refresh]);

    return (
        loading 
        ? <Skeleton className={classes.loading} />
        : <DetailsCard data={patientDetails || []}/>
    )
}