import React, { useEffect } from 'react';
import AlertForm from 'ui-components/dist/AlertForm/AlertForm';
import { editPatientAlert, getAlertsMetadata } from '../api/alertsApi';
import { getToken } from '../utils/authenticator';
import { getMeasurementsForConditionType } from '../utils/conditionTypeMeasurementConfig';
import { getUnitForMeasurementType, prepMeasurementFields } from '../utils/pageOperations/patientPageOperations';
import useForm from '../hooks/useForm';
import { Mixpanel } from '../mixpanel';

const timeframesAndAggregators = {
    '0': 'Any new measurement reading',
    '1': 'The highest measurement reading in a day',
    '3': 'Consecutive highest measurement readings over 3 days',
    '7': 'Consecutive highest measurement readings over 7 days'
};

const comparators = {
    '>':'greater than ( > )',
    '>=':'greater than or equal to ( >= )',
    '<':'less than ( < )',
    '<=':'less than or equal to ( <= )',
    '%<':'percentage decrease from set value',
    '%>':'percentage increase from set value'
};

export default function EditAlertModalContainer(props) {
    const {
        open,
        patientId,
        handleClose,
        handleCancel,
        conditionType,
        onSubmit,
        initialFieldValues = {
            alertId: null,
            measurementField: '',
            comparator: '',
            percentChange: '',
            baseline: '',
            threshold: '',
            timeframeDays: '',
            alertVia: {
                send_email: {
                    id: 1,
                    label: 'Email',
                    value: false
                }, 
                send_sms: {
                    id: 2,
                    label: 'SMS',
                    value: false
                }, 
                send_voice_call: {
                    id: 3,
                    label: 'Phone',
                    value: false
                }
            }
        }
    } = props;

    const validate = (fieldValues = values) => {
        let temp = {...errors};
        if('percentChange' in fieldValues && ['%<', '%>'].includes(values.comparator)) {
            temp.percentChange = fieldValues.percentChange ? '' : 'This field is required';
        }
        if('baseline' in fieldValues && ['%<', '%>'].includes(values.comparator)) {
            temp.baseline = fieldValues.baseline ? '' : 'This field is required';
        }
        if('measurementField' in fieldValues) {
            temp.measurementField = fieldValues.measurementField ? '' : 'This field is required';   
        }
        if('comparator' in fieldValues) {
            temp.comparator = fieldValues.comparator ? '' : 'This field is required';
        }
        if('timeframeDays' in fieldValues) {
            temp.timeframeDays = fieldValues.timeframeDays ? '' : 'This field is required';
        }
        if('threshold' in fieldValues && ['<', '<=', '=>', '>'].includes(values.comparator)) {
            temp.threshold = fieldValues.threshold ? '' : 'This field is required';
        }

        setErrors({
            ...temp
        });

        if(fieldValues === values) {
            return Object.values(temp).every((tempValue) => tempValue === '');
        }
    };

    const updateValues = (name, value) => {
        switch(name) {
            case 'measurementField':
                Mixpanel.track('Edit Alert Modal: Changed Measurement type');
                break;
            case 'comparator':
                Mixpanel.track('Edit Alert Modal: Changed Operator Type');
                break;
            case 'percentChange':
                Mixpanel.track('Edit Alert Modal: Changed Percentage Value');
                break;
            case 'baseline':
                Mixpanel.track('Edit Alert Modal: Changed Baseline Value');
                break;
            case 'timeframeDays':
                Mixpanel.track('Edit Alert Modal: Changed Time frame');
                break;
            case 'alertVia':
                Mixpanel.track('Edit Alert Modal: Changed Alert Destination Checkboxes');
                break;
            default:
                break;
        }

        if(name === 'measurementField') {
            getNewUnit(value);
        }

        if ((name === 'threshold' && values.baseline) || (name === 'threshold' && values.percentChange)) {
            setValues({
                ...values,
                baseline: '',
                percentChange: '',
                threshold: value,
            });
        }

        if (name === 'percentChange' && values.threshold) {
            setValues({
                ...values,
                threshold: '',
                percentChange: value
            });
        }

        if (name === 'baseline' && values.threshold) {
            setValues({
                ...values,
                threshold: '',
                baseline: value
            });
        }
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFieldValues, true, validate, updateValues);

    const [stage, setStage] = React.useState(4);
    const [measurements, setMeasurements] = React.useState([]);
    const [currentMethod, setCurrentMethod] = React.useState({});
    const [measurementFields, setMeasurementFields] = React.useState({});
    const [submitDisabled, setSubmitDisabled] = React.useState(true);
    const [backendErrors, setBackendErrors] = React.useState({});

    useEffect(() => {
        if(conditionType){
            setMeasurements(getMeasurementsForConditionType(conditionType));
            setCurrentMethod(getMeasurementsForConditionType(conditionType)[0]);
        }
    }, [conditionType]);

    useEffect(() => {
        const getAlertsMetadataFromApi = async () => {
            const metadata = await getAlertsMetadata(getToken());

            setMeasurementFields(prepMeasurementFields(metadata, measurements));
        }

        if(measurements && measurements.length >= 1) {
            getAlertsMetadataFromApi();
        }
    }, [measurements]);

    const getNewUnit = (newUnit) => {
        measurements.forEach((element) => {
            if(element.key === newUnit) {
                setCurrentMethod(element);        
            }
        });
    };

    useEffect(() => {
        if(initialFieldValues.measurementField !== '') {
            if (Object.keys(backendErrors).length === 0) {
                setValues(initialFieldValues);
            }
            getNewUnit(initialFieldValues.measurementField);
        }
    // disabling warning as getNewUnit() is fired in places other than this side effect
    // it's technically not a dependency as it's a stateless function 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialFieldValues]);

    const prepAlertForApi = (values) => {
        const getComparatorForApi = (comparator) => {
            if(['<','>','<=','>='].includes(comparator)) {
                return(comparator);
            }
    
            if(comparator === '%>') {
                return('>');
            }
    
            if(comparator === '%<') {
                return('<');
            }
        };
    
        const getTimeframeDaysForApi = (timeframe) => {
            if(timeframe === '0') {
                return(null);
            }
    
            return parseFloat(timeframe);
        };
    
        const getAggregatorForApi = (timeframe) => {
            if(timeframe === '0') {
                return(null);
            }
    
            return 'MAX';
        };

        let measurementField= values.measurementField;
        let baseline= parseFloat(values.baseline);
        let percentChange = parseFloat(values.percentChange);
        let comparator = getComparatorForApi(values.comparator);
        let timeframeDays = getTimeframeDaysForApi(values.timeframeDays);
        let threshold = parseFloat(values.threshold);
        let aggregator = getAggregatorForApi(values.timeframeDays);
    
        const makeName = () => {
            return(`Highest or max ${timeframeDays} days ${measurementField} ${comparator} ${percentChange ? `${percentChange} of baseline ${baseline}` : `${threshold}`}`);
        };

        const prepTriggerObjects = () => {
            const triggerObjects = {
                measurement_field: measurementField,
                comparator: comparator,
                timeframe_days: timeframeDays
            };

            if(threshold) {
                triggerObjects.threshold = threshold;
            }

            if(baseline) {
                triggerObjects.baseline = baseline
            }

            if(percentChange) {
                triggerObjects.percent_change = percentChange;
            }

            if(aggregator) {
                triggerObjects.aggregator = aggregator;
            }

            return triggerObjects;
        }

        let alert = {
            name: makeName(),
            patient: parseInt(patientId),
            send_email: values.alertVia.send_email.value,
            send_sms: values.alertVia.send_sms.value,
            send_voice_call: values.alertVia.send_voice_call.value,
            active: true,
            trigger_objects: [
                prepTriggerObjects()
            ]
        };

        return(alert);
    };

    const handleSubmit = async () => {
        Mixpanel.track('Edit Alert Modal: Confirm');
        if(validate()){
            const response = await editPatientAlert(values.alertId, prepAlertForApi(values), getToken());

            if(response.status === 400) {
                const responseJson = await response.json();
                setBackendErrors(responseJson);
            }

            if(response.status === 200) {
                resetForm();
                setStage(4);
                setBackendErrors({});
            }


            onSubmit(response.status);
        }
    };

    useEffect(() => {
        const checkSumbitDisabled = () => {
            if(JSON.stringify(values) === JSON.stringify(initialFieldValues)) {
                return(true);
            }

            if(values.measurementField) {
                if(values.comparator && ['<', '>', '<=', '>='].includes(values.comparator)) {
                    if(values.threshold && values.timeframeDays) {
                        return(false);
                    }
                } else if (values.comparator && ['%<', '%>'].includes(values.comparator)) {
                    if(values.percentChange && values.baseline && values.timeframeDays) {
                        return(false);
                    }
                }
            } 
            return(true);
        }
        setSubmitDisabled(checkSumbitDisabled());
    }, [values, initialFieldValues]);

    return (
        <AlertForm
            open={open}
            values={values}
            stage={stage}
            comparisonMode=''
            measurementFields={measurementFields}
            timeframesAndAggregators={timeframesAndAggregators}
            comparators={comparators}
            unit={getUnitForMeasurementType(currentMethod)}
            submitDisabled={submitDisabled}
            onSubmit={handleSubmit}
            onClose={()=>{
                handleClose();
                setStage(4);
                setBackendErrors({});
            }}
            onCancel={() => {
                handleCancel();
                setStage(4);
                setBackendErrors({});
            }}
            backendErrors={backendErrors}
            handleInputChange={handleInputChange}
            resetForm={resetForm}
            errors={errors}
            mode='edit'
            initialFieldValues={initialFieldValues}
        />
    )
}
