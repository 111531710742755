import React from 'react';
import moment from 'moment';
import { getToken } from '../authenticator';
import { applyPatientFilter, applyPatientFilterForHospital } from '../../api/patientsApi';

export const prepStatusData = (data) => {
    const statuses = [];

    data.forEach((element) => {
        statuses.push({
            'label': element.label,
            'value': element.count
        })
    });

    return statuses;
};

export const prepVitalsData = async (data) => {
    const vitals = [];

    for(let i = 0; i < data.length; i++) {
        let vitalTableData = [];

        if(data[i].count > 0) {
            const result = await applyPatientFilter(data[i].id, getToken());
            result.forEach((element) => {
                delete element.id;
                const newEntry = {
                    name: element.name,
                    email: element.email,
                    lastUsed: element.last_used ? moment(element.last_used).fromNow() : '-',
                    lastReviewed: element.latest_review_event ? moment(element.latest_review_event.time).fromNow() : '-',
                    lastReviewedBy: element.latest_review_event ? element.latest_review_event.reviewed_by_name : '-',
                }
                vitalTableData.push(newEntry);
            })
        } else {
            vitalTableData = null;
        }

        vitals.push({
            'label': data[i].name,
            'value': data[i].count,
            'data': data[i].count > 0 ? vitalTableData : [],
            'description': data[i].description,
        })
    }

    return vitals;
};

export const prepHospitalVitalsData = async (data, hospitalId) => {
    const vitals = [];

    for(let i = 0; i < data.length; i++) {
        let vitalTableData = [];

        if(data[i].count > 0) {
            const result = await applyPatientFilterForHospital(data[i].id, hospitalId, getToken());
            result.forEach((element) => {
                delete element.id;
                const newEntry = {
                    name: element.name,
                    email: element.email,
                    lastUsed: element.last_used ? moment(element.last_used).fromNow() : '-',
                    lastReviewed: element.latest_review_event ? moment(element.latest_review_event.time).fromNow() : '-',
                    lastReviewedBy: element.latest_review_event ? element.latest_review_event.reviewed_by_name : '-',
                }
                vitalTableData.push(newEntry);
            })
        } else {
            vitalTableData = null;
        }

        vitals.push({
            'label': data[i].name,
            'value': data[i].count,
            'data': data[i].count > 0 ? vitalTableData : [],
            'description': data[i].description,
        })
    }

    return vitals;
};

export const prepMenuItems = (data) => {
    const items = [];

    data.forEach((element, index) => {
        items.push(<option value={element.id} key={`hospital-menu-item-${index}`}>{element.name}</option>)
    })

    return items;
};