import ls from "local-storage";
import {
  convertToBloodGlucoseUnit,
  convertToFahrenheit,
  roundToX,
  setRange,
} from "../utils/unitOperations";

export const getMeasurementsForConditionType = (condition_type) => {
  switch (condition_type) {
    case "covid-19":
      return [
        oxygenSaturation,
        temperature,
        breathlessness,
        diarrhoea,
        heartRate,
      ];
    case "ipf":
      return [
        height,
        weight,
        fvc,
        fvcPercentPredicted,
        fev1,
        fev1PercentPredicted,
        dyspnea,
        oxygenSaturation,
        heartRate,
        temperature,
        systolicBloodPressure,
        diastolicBloodPressure,
        haemoglobin,
      ];
    case "lung-transplant":
      return [
        height,
        weight,
        fev1,
        fef25_75,
        fvc,
        dyspnea,
        oxygenSaturation,
        heartRate,
        temperature,
        systolicBloodPressure,
        diastolicBloodPressure,
        haemoglobin,
        bloodGlucose,
        cniTrough,
      ];
    case "cystic-fibrosis":
      return [
        height,
        weight,
        fvc,
        fvcPercentPredicted,
        fev1,
        fev1PercentPredicted,
        fev1Fvc,
        oxygenSaturation,
        heartRate,
        sputumVolume,
        temperature,
      ];
    case "copd":
      return [
        height,
        weight,
        fvc,
        fvcPercentPredicted,
        fev1,
        fev1PercentPredicted,
        fev1Fvc,
        oxygenSaturation,
        heartRate,
        oxygenFlowRate,
        temperature,
        systolicBloodPressure,
        diastolicBloodPressure,
        bloodGlucose,
        respiratoryRate,
        rockwoodIndex,
        barthelIndex,
        pef,
      ];
    case "asthma":
      return [
        height,
        weight,
        fev1,
        fev1PercentPredicted,
        fev1Fvc,
        fvc,
        fvcPercentPredicted,
        oxygenSaturation,
        heartRate,
        systolicBloodPressure,
        diastolicBloodPressure,
        temperature,
        oxygenFlowRate,
        bloodGlucose,
        pef,
      ];
    case "vasculitis":
      return [
        height,
        weight,
        systolicBloodPressure,
        diastolicBloodPressure,
        oxygenSaturation,
        heartRate,
        temperature,
        haemoglobin,
      ];
    case "kidney-transplant":
      return [
        height,
        weight,
        systolicBloodPressure,
        diastolicBloodPressure,
        cniTrough,
        creatinine,
        gfr,
        haemoglobin,
        whiteBloodCells,
        temperature,
        oxygenSaturation,
        heartRate,
      ];
    case "prostate-cancer":
      return [
        height,
        weight,
        psa,
        systolicBloodPressure,
        diastolicBloodPressure,
      ];
    case "hypertension":
      return [
        height,
        weight,
        systolicBloodPressure,
        diastolicBloodPressure,
        oxygenSaturation,
        heartRate,
        temperature,
      ];
    case "haemodialysis":
      return [
        height,
        weight,
        systolicBloodPressure,
        diastolicBloodPressure,
        phosphate,
        oxygenSaturation,
        heartRate,
        temperature,
        haemoglobin,
      ];
    default:
      return [
        {
          label: "-",
          key: "-",
          range: [0, 10],
          unit: "-",
        },
      ];
  }
};

const barthelIndex = {
  label: "Modified Barthel Index",
  key: "barthel_index",
  range: (data) => [0, 20],
  unit: "0-100",
  displayWithUnit: (value) => `${value}`,
  canAddMeasurement: true,
};

const rockwoodIndex = {
  label: "Rockwood Frailty Index",
  key: "rockwood_index",
  range: (data) => [1, 9],
  unit: "1-9",
  displayWithUnit: (value) => `${value}`,
  canAddMeasurement: true,
};

const respiratoryRate = {
  label: "Respiratory Rate",
  key: "respiratory_rate",
  range: (data) => [20, 40],
  unit: "BPM",
  displayWithUnit: (value) => `${value} BPM`,
  canAddMeasurement: true,
};

const bloodGlucose = {
  label: "Blood Glucose",
  key: "blood_glucose",
  range: (data) => [0, 10],
  unit: ls.get("blood_glucose_unit"),
  displayWithUnit: (value) => {
    const unit = ls.get("blood_glucose_unit");
    return `${roundToX(convertToBloodGlucoseUnit(value, unit), 2)} ${unit}`;
  },
  canAddMeasurement: true,
};

const heartRate = {
  label: "Heart Rate",
  key: "heart_rate",
  range: (data) => [30, 140],
  unit: "BPM",
  displayWithUnit: (value) => `${value} BPM`,
  canAddMeasurement: true,
};

const oxygenFlowRate = {
  label: "Oxygen Flow Rate",
  key: "oxygen_rate",
  range: (data) => [0, 4],
  unit: "L/pm",
  displayWithUnit: (value) => `${value} L/pm`,
  canAddMeasurement: true,
};

const fvc = {
  label: "FVC",
  key: "fvc",
  range: (data) => [2, 4],
  unit: "L",
  displayWithUnit: (value) => `${value} L`,
  canAddMeasurement: true,
};

const fev1 = {
  label: "FEV 1",
  key: "fev1",
  range: (data) => [2, 4],
  unit: "L",
  displayWithUnit: (value) => `${value} L`,
  canAddMeasurement: true,
};

const fev1Fvc = {
  label: "FEV1 / FVC",
  key: "fev1_fvc",
  range: (data) => [0, 1],
  unit: "%",
  displayWithUnit: (value) => `${roundToX(value * 100, 2)}%`,
  canAddMeasurement: true,
};

const fef25_75 = {
  label: "FEF 25/75",
  key: "fev25_75", // key is incorrect in the API, should be fef25_75
  range: (data) => [2, 4],
  unit: ls.get("flow_rate_unit") === "L/min" ? "L/min" : "L/s",
  displayWithUnit: (value) =>
    `${roundToX(
      ls.get("flow_rate_unit") === "L/min" ? value * 60 : value,
      2
    )} ${ls.get("flow_rate_unit") === "L/min" ? "L/min" : "L/s"}`,
  canAddMeasurement: true,
};

const cniTrough = {
  label: "CNI Trough",
  key: "cni_trough",
  range: (data) => {
    return setRange(data, 0, 3000, 10, [0, 100]);
  },
  unit: "ng/ml",
  displayWithUnit: (value) => `${roundToX(value, 2)} ng/ml`,
  canAddMeasurement: true,
};

const creatinine = {
  label: "Creatinine",
  key: "creatinine",
  range: (data) => [0, 10],
  unit: "μmol/L",
  displayWithUnit: (value) => `${roundToX(value, 2)} μmol/L`,
  canAddMeasurement: true,
};

const gfr = {
  label: "GFR",
  key: "gfr",
  range: (data) => [0, 10],
  unit: "",
  displayWithUnit: (value) => `${roundToX(value, 2)}`,
  canAddMeasurement: true,
};

const whiteBloodCells = {
  label: "White Blood Cells",
  key: "white_cells",
  range: (data) => [0, 30],
  unit: "x10^9/L",
  displayWithUnit: (value) => `${roundToX(value, 2)} x10^9/L`,
  canAddMeasurement: true,
};

const psa = {
  label: "PSA",
  key: "psa",
  range: (data) => [0, 100],
  unit: "ng/ml",
  displayWithUnit: (value) => `${roundToX(value, 2)} ng/ml`,
  canAddMeasurement: true,
};

const weight = {
  label: "Weight",
  key: "weight",
  range: (data) => [35, 115],
  unit: ls.get("weight_unit"),
  displayWithUnit: (value) => {
    const unit = ls.get("weight_unit");
    if (unit === "lb") {
      return `${roundToX(value * 2.205, 2)}lbs`;
    }
    if (unit === "st") {
      const valInLbs = parseFloat(value * 2.205);

      const lbs = valInLbs % 14;
      const stone = (valInLbs - lbs) / 14;

      return `${stone}st ${roundToX(lbs, 2)}lbs`;
    } else {
      return `${roundToX(value, 2)}kg`;
    }
  },
  canAddMeasurement: true,
};

const systolicBloodPressure = {
  label: "Systolic Blood Pressure",
  key: "bp_systolic",
  range: (data) => [100, 160],
  unit: "mmHg",
  displayWithUnit: (value) => `${roundToX(value, 2)} mmHg`,
  canAddMeasurement: true,
};

const diastolicBloodPressure = {
  label: "Diastolic Blood Pressure",
  key: "bp_diastolic",
  range: (data) => [65, 100],
  unit: "mmHg",
  displayWithUnit: (value) => `${roundToX(value, 2)} mmHg`,
  canAddMeasurement: true,
};

const phosphate = {
  label: "Phosphate",
  key: "phosphate",
  range: (data) => [0, 20],
  unit: "mg/dL",
  displayWithUnit: (value) => `${roundToX(value, 2)} mg/dL`,
  canAddMeasurement: true,
};

const oxygenSaturation = {
  label: "Oxygen Saturation",
  key: "pulse_ox",
  range: (data) => [85, 100],
  unit: "%",
  displayWithUnit: (value) => `${roundToX(value, 2)}%`,
  canAddMeasurement: true,
};

const temperature = {
  label: "Temperature",
  key: "temperature",
  range: (data) => {
    if (ls.get("temperature_unit") === "°F") {
      return [85, 110];
    } else {
      return [30, 45];
    }
  },
  unit: ls.get("temperature_unit") === "°F" ? "\xB0F" : "\xB0C",
  displayWithUnit: (value) => {
    const unit = ls.get("temperature_unit");
    if (unit === "°F") {
      return `${roundToX(convertToFahrenheit(value, unit), 2)}\xB0F`;
    } else {
      return `${roundToX(value, 2)}\xB0C`;
    }
  },
  canAddMeasurement: true,
};

const haemoglobin = {
  label: "Haemoglobin",
  key: "hemoglobin",
  range: (data) => [5, 20],
  unit: "g/dL",
  displayWithUnit: (value) => `${roundToX(value, 2)} g/dL`,
  canAddMeasurement: true,
};

const breathlessness = {
  label: "Breathlessness",
  key: "breathlessness",
  range: (data) => [0, 4],
  unit: "mMRC",
  displayWithUnit: (value) => `${roundToX(value, 2)}`,
  canAddMeasurement: true,
};

const diarrhoea = {
  label: "Diarrhoea",
  key: "diarrhoea",
  range: (data) => [0, 10],
  unit: "0-10",
  displayWithUnit: (value) => `${roundToX(value, 2)}`,
  canAddMeasurement: true,
};

const dyspnea = {
  label: "Dyspnea",
  key: "dyspnea",
  range: (data) => [0, 4],
  unit: "0-4",
  displayWithUnit: (value) => `${roundToX(value, 2)}`,
  canAddMeasurement: true,
};

const sputumVolume = {
  label: "Sputum Volume",
  key: "sputum_volume",
  range: (data) => [0, 10],
  unit: "ml",
  displayWithUnit: (value) => `${roundToX(value, 2)}ml`,
  canAddMeasurement: true,
};

const height = {
  label: "Height",
  key: "height",
  range: (data) => [100, 200],
  unit: "cm",
  displayWithUnit: (value) => `${roundToX(value, 2)}cm`,
  canAddMeasurement: true,
};

const fvcPercentPredicted = {
  label: "% Predicted FVC",
  key: "percent_predicted_fvc",
  range: (data) => [70, 100],
  unit: "%",
  displayWithUnit: (value) => `${roundToX(value, 2)}%`,
  canAddMeasurement: false,
};

const fev1PercentPredicted = {
  label: "% Predicted FEV1",
  key: "percent_predicted_fev1",
  range: (data) => [70, 100],
  unit: "%",
  displayWithUnit: (value) => `${roundToX(value, 2)}%`,
  canAddMeasurement: false,
};

const pef = {
  label: "PEF",
  key: "pef",
  range: (data) => [2, 4],
  unit: ls.get("flow_rate_unit") === "L/min" ? "L/min" : "L/s",
  displayWithUnit: (value) =>
    `${roundToX(
      ls.get("flow_rate_unit") === "L/min" ? value * 60 : value,
      2
    )} ${ls.get("flow_rate_unit") === "L/min" ? "L/min" : "L/s"}`,
  canAddMeasurement: true,
};
