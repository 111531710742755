import moment from "moment";
import ls from "local-storage";
import { roundToX } from "../unitOperations";

export const prepDataAreaData = (response, field, startDate, endDate) => {
  const preppedData = [];

  response.forEach((element) => {
    if (
      moment(startDate.format("Y-M-D")).isAfter(element.date) ||
      moment(endDate.format("Y-M-D")).isBefore(element.date)
    ) {
      return;
    }

    if (element[field] !== null) {
      preppedData.push({
        x: Date.parse(element.time),
        y: parseFloat(roundToX(element[field], 2)),
      });
    }
  });

  return preppedData.reverse();
};

export const prepMeasurementTableData = (
  response,
  measurementType,
  startDate,
  endDate
) => {
  const preppedData = [];

  response.forEach((element) => {
    let value;

    value = measurementType.displayWithUnit(element[measurementType.key]);

    if (moment(startDate.format("Y-M-D")).isAfter(element.date)) {
    } else if (moment(endDate.format("Y-M-D")).isBefore(element.date)) {
    } else if (element[measurementType.key] !== null) {
      preppedData.push({
        date: moment(element.time).format("MMM Do YYYY"),
        time: moment(element.time).format("h:mm a"),
        value: `${value}`,
      });
    }
  });

  return preppedData;
};

export const prepReviewData = (data, user) => {
  const result = [];

  data.forEach((element) => {
    const review = {
      id: element.id || "-",
      date: {
        label: element.time ? moment(element.time).format("DD MMM Y") : "-",
      },
      reviewedByName: { label: element.reviewed_by_name || "-" },
      notes: { label: element.notes || "-" },
      disabled: element.reviewed_by === user ? false : true,
    };

    result.push(review);
  });

  return result;
};

export const prepTriggeredAlertsData = (data) => {
  const result = [];

  const getTimeframeDays = (data) => {
    switch (data) {
      case null:
        return "Any reading";
      case 1:
        return "Highest of the day";
      case 3:
        return "3 days";
      case 7:
        return "7 days";
      default:
        return "-";
    }
  };

  data.forEach((element) => {
    const triggeredAlert = {
      id: element.id,
      type: { label: element.trigger_measurement || "-" },
      trigger: {
        label: element.trigger_short_summary || "-",
        component: "tooltip",
        tooltipLabel: element.trigger_summary || "-",
      },
      timeFrame: { label: getTimeframeDays(element.trigger_timeframe_days) },
      timeOfAlert: {
        label: element.time
          ? moment(element.time).format("h:mm A, MMM DD, YYYY")
          : "-",
      },
      alertStatus: { label: element.resolved_at ? "Resolved" : "Unresolved" },
      resolvedBy: {
        label: element.resolved_at
          ? `${element.resolved_by_staff || ""} ${moment(
              element.resolved_at
            ).format("h:mm A, MMM DD, YYYY")}`
          : "-",
      },
      resolve: element.resolved_at
        ? { label: "-" }
        : { label: "RESOLVE", component: "button" },
      highlighted: element.resolved_at ? false : true,
    };

    result.push(triggeredAlert);
  });

  return result;
};

export const prepConfiguredAlertsData = (data) => {
  const result = [];

  const getTimeframeDays = (data) => {
    switch (data) {
      case null:
        return "Any reading";
      case 1:
        return "Highest of day";
      case 3:
        return "3 days";
      case 7:
        return "7 days";
      default:
        return "-";
    }
  };

  const getMeasurementName = (triggerObjects) => {
    if (triggerObjects[0]) {
      return triggerObjects[0].get_measurement_field_display;
    }

    return "-";
  };

  const getTriggerShort = (triggerObjects) => {
    if (triggerObjects[0]) {
      return triggerObjects[0].short_summary;
    }

    return "-";
  };

  const getTriggerLong = (triggerObjects) => {
    if (triggerObjects[0]) {
      return triggerObjects[0].clinician_summary;
    }

    return "-";
  };

  const getCheckOrXValue = (value) => {
    if (value) {
      return "check";
    }

    return "cross";
  };

  data.forEach((element) => {
    const configuredAlert = {
      id: element.id,
      type: { label: getMeasurementName(element.trigger_objects) },
      trigger: {
        label: getTriggerShort(element.trigger_objects),
        component: "tooltip",
        tooltipLabel: getTriggerLong(element.trigger_objects),
      },
      timeFrame: {
        label: element.trigger_objects[0]
          ? getTimeframeDays(element.trigger_objects[0].timeframe_days)
          : "-",
      },
      email: { value: getCheckOrXValue(element.send_email), component: "icon" },
      sms: { value: getCheckOrXValue(element.send_sms), component: "icon" },
      call: {
        value: getCheckOrXValue(element.send_voice_call),
        component: "icon",
      },
      creationDate: {
        label: moment(element.created_at).format("h:mm A, MMM DD, YYYY"),
      },
      summary: getTriggerLong(element.trigger_objects),
    };

    result.push(configuredAlert);
  });

  return result;
};

export const prepPatientDetails = (data) => {
  const patientDetails = [
    { label: "ID", value: data.id },
    { label: "Hospital Patient ID", value: data.hospital_patient_id },
    { label: "Name", value: data.name },
    { label: "Email", value: data.email },
    {
      label: "Last Review",
      value: data.latest_review_event
        ? moment(data.latest_review_event.time).format("MMM Do, hh:mma")
        : null,
    },
    {
      label: "Last Reviewed By",
      value: data.latest_review_event
        ? data.latest_review_event.reviewed_by_name
        : null,
    },
    { label: "Phone", value: data.phone_number },
    { label: "Hospital", value: data.hospital_name || "-" },
    {
      label: "Last Used",
      value: data.last_used
        ? moment(data.last_used).format("MMM Do, hh:mma")
        : null,
    },
    { label: "Caregiver Phone", value: data.caregiver_phone || "-" },
  ];

  if (data.consultant_name) {
    patientDetails.push({
      label: "Consultant Name",
      value: data.consultant_name,
    });
  }

  return patientDetails;
};

export const isSpirometryCondition = (condition) => {
  const spirometryConditions = [
    "ipf",
    "lung-transplant",
    "cystic-fibrosis",
    "copd",
    "asthma",
  ];

  return spirometryConditions.includes(condition) ? true : false;
};

export const prepAllowedDates = (data) => {
  const allowedDates = [];

  data.forEach((element) => {
    if (
      !allowedDates.includes(moment(element.start_time).format("DD/MM/YYYY"))
    ) {
      allowedDates.push(moment(element.start_time).format("DD/MM/YYYY"));
    }
  });

  return allowedDates;
};

export const prepSpirometryData = (data) => {
  const prepPEFBySelectedUnit = (pefData) => {
    const selectedUnit = ls.get("flow_rate_unit");

    if (selectedUnit === "L/min") {
      return pefData * 60;
    }

    return pefData;
  };

  return data.map((element) => {
    return {
      Date: moment(element.start_time).calendar(null, {
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: "dddd",
        lastDay: "[Yesterday]",
        lastWeek: "[Last] dddd",
        sameElse: "MMM DD YYYY",
      }),
      Time: moment(element.start_time).format("h:mma"),
      FEV1: element.fev1,
      FVC: element.fvc,
      "FEV1 / FVC": element.fev1_fvc,
      PEF: prepPEFBySelectedUnit(element.pef),
    };
  });
};

export const prepDataForNewDate = (newDate, data) => {
  const newData = [];

  data.forEach((element) => {
    if (
      moment(element.start_time).format("DD/MM/YYYY") ===
      moment(newDate).format("DD/MM/YYYY")
    ) {
      let spirometryMeasurement = element;
      spirometryMeasurement.flowVolumeCurveData = [];
      spirometryMeasurement.flowVolumeRange = 6;
      spirometryMeasurement.flowVolumePointsOfInterest = [];
      spirometryMeasurement.volumeTimeCurveData = [];
      spirometryMeasurement.volumeTimeRange = 6;
      spirometryMeasurement.volumeTimePointsOfInterest = [];

      newData.push(spirometryMeasurement);
    }
  });

  return newData;
};

export const prepFlowVolumePointsOfInterest = (spirometryData, graphData) => {
  const getPEFValueBySelectedUnit = (pefValue) => {
    const selectedUnit = ls.get("flow_rate_unit");

    if (selectedUnit === 'L/min') {
      return pefValue * 60;
    }

    return pefValue;
  };

  const getEnd = () => {
    let highest = { x: 0, y: 0 };

    graphData.forEach((element) => {
      if (element.y > highest.y) {
        highest = element;
      }
    });

    return highest.x;
  };

  const end = getEnd();

  return [
    {
      start: 0,
      end: end,
      value: getPEFValueBySelectedUnit(parseFloat(spirometryData.pef)),
      label: "PEF",
    },
  ];
};

export const isAddMeasurementSubmitDisabled = (
  primaryMeasurement,
  secondaryMeasurement,
  needsSecondaryMeasurement
) => {
  if (needsSecondaryMeasurement && !secondaryMeasurement) {
    return true;
  }

  if (!primaryMeasurement) {
    return true;
  }

  return false;
};

export const needsSecondaryMeasurement = (key) => {
  switch (key) {
    case "weight":
      if (ls.get("weight_unit") === "st") {
        return true;
      }
      break;
    default:
      return false;
  }
};

export const prepMeasurementFields = (metadata, measurements) => {
  let newMeasurements = {};

  if (!metadata.measurement_fields) {
    return [];
  }

  const measurementKeys = measurements.map((element) => {
    return element.key;
  });

  for (const [key, value] of Object.entries(metadata.measurement_fields)) {
    if (measurementKeys.includes(key)) {
      newMeasurements[key] = value;
    }
  }

  return newMeasurements;
};

export const getAlertDataById = (data, id) => {
  let alert = null;
  data.forEach((element) => {
    if (element.id === id) {
      alert = element;
    }
  });

  return alert;
};

export const prepDataForEditAlertModal = (data) => {
  if (!data) {
    return null;
  }

  let alert = {
    alertId: data.id,
    measurementField: data.trigger_objects[0].measurement_field,
    timeframeDays: data.trigger_objects[0].timeframe_days
      ? data.trigger_objects[0].timeframe_days.toString()
      : "0",
    alertVia: {
      send_email: {
        id: 1,
        label: "Email",
        value: data.send_email,
      },
      send_sms: {
        id: 2,
        label: "SMS",
        value: data.send_sms,
      },
      send_voice_call: {
        id: 3,
        label: "Phone",
        value: data.send_voice_call,
      },
    },
  };

  if (data.trigger_objects[0]) {
    if (data.trigger_objects[0].percent_change) {
      alert.percentChange = data.trigger_objects[0].percent_change.toString();
      alert.baseline = data.trigger_objects[0].baseline.toString();
      alert.threshold = "";

      switch (data.trigger_objects[0].comparator) {
        case "<":
          alert.comparator = "%<";
          break;
        case ">":
          alert.comparator = "%>";
          break;
        case "<=":
          alert.comparator = "%<";
          break;
        case ">=":
          alert.comparator = "%>";
          break;
        default:
          break;
      }
    } else if (data.trigger_objects[0].threshold) {
      alert.threshold = data.trigger_objects[0].threshold.toString();
      alert.percentChange = "";
      alert.baseline = "";
      alert.comparator = data.trigger_objects[0].comparator;
    }
  }

  return alert;
};

export const getUnitForMeasurementType = (method) => {
  if (method) {
    if (method.key === "weight") {
      return "kg";
    } else if (method.key === "blood_glucose") {
      return "mmol/L";
    } else if (method.key === "temperature") {
      return "°C";
    }

    if (method.unit) {
      return method.unit;
    }
  }

  return "";
};
