export const convertToCelsius = (value, unit) => {
  if (unit === "°F") {
    return (value - 32) / 1.8;
  } else {
    return value;
  }
};

export const convertToFahrenheit = (value, unit) => {
  if (unit === "°F") {
    return value * 1.8 + 32;
  } else {
    return value;
  }
};

export const convertToLPerMinute = (value, unit) => {
  if (unit === "L/min") {
    return value;
  } else {
    return value * 60;
  }
};

export const roundToX = (value, digits) => {
  if (typeof value === "string" || value instanceof String) {
    return (
      Math.round((parseFloat(value) + Number.EPSILON) * Math.pow(10, digits)) /
      Math.pow(10, digits)
    );
  }
  return (
    Math.round((value + Number.EPSILON) * Math.pow(10, digits)) /
    Math.pow(10, digits)
  );
};

export const convertToLPerSecond = (value, unit) => {
  if (unit === "L/min") {
    return roundToX(value / 60, 6);
  } else {
    return value;
  }
};

export const convertToKg = (value, unit) => {
  if (unit === "lb") {
    return value / 2.205;
  }
  if (unit === "st") {
    const stoneToLbs = parseFloat(value.stone) * 14;

    return (stoneToLbs + parseFloat(value.lbs)) / 2.205;
  }
  return value;
};

export const convertToWeightUnit = (value, unit) => {
  if (unit === "lb") {
    return value * 2.205;
  }
  if (unit === "st") {
    const valInLbs = parseFloat(value * 2.205);

    const lbs = valInLbs % 14;
    const stone = (valInLbs - lbs) / 14;

    return { stone: stone, lbs: lbs };
  }
  return parseFloat(value);
};

export const convertToMmol = (value, unit) => {
  if (unit === "mg/dL") {
    return roundToX(value / 18, 4);
  }
  return value;
};

export const convertToBloodGlucoseUnit = (value, unit) => {
  if (unit === "mg/dL") {
    return value * 18;
  }
  return parseFloat(value);
};

/**
 *
 * @param {array} data - The graph data currently displayed for this measurement
 * @param {number} bottom - The lowest value the CMS allows for this measurement
 * @param {number} top - The highest value the CMS allows for this measurement
 * @param {number} margin - The percentage amount to add above and below the range
 * @param {array} defaultRange - Range to return if data is empty
 */
export const setRange = (data, bottom, top, margin, defaultRange) => {
  if (data.length <= 0) {
    return defaultRange;
  }

  let lowest = top;
  let highest = bottom;

  data.forEach((element) => {
    if (element.y < lowest) {
      lowest = element.y;
    }
    if (element.y > highest) {
      highest = element.y;
    }
  });

  const difference = highest - lowest;
  const xPercent = (margin / 100) * difference;

  if (difference < 2) {
    lowest = Math.trunc(lowest - 2);
    highest = Math.trunc(highest + 2);

    if (lowest < bottom) {
      lowest = bottom;
    }
    if (highest > top) {
      highest = top;
    }
    return [lowest, highest];
  } else {
    lowest = Math.trunc(lowest - xPercent);
    highest = Math.trunc(highest + xPercent);

    if (lowest < bottom) {
      lowest = bottom;
    }
    if (highest > top) {
      highest = top;
    }
    return [lowest, highest];
  }
};
