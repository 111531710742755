import React from 'react';
import LatestMeasurementCardContainer from './LatestMeasurementCardContainer';

export default function LatestMeasurementsContainer(props) {
    const { measurements, patientId, conditionLoading, onMeasurementChange, measurementsAdded } = props;

    const handleChange = (measurement) => {
        onMeasurementChange(measurement)
    }

    return (
        conditionLoading
        ? null
        : measurements.map((measurement, index) => {
            return <LatestMeasurementCardContainer
                patientId={patientId}
                measurement={measurement}
                onMeasurementChange={handleChange}
                measurementsAdded={measurementsAdded}
                left={index % 5 === 0 ? true : false }
                right={index % 5 === 4 ? true : false}
                key={`measurement-card-${index}`}
            />
        })
    )
}
