import { cmsPostRequest, cmsGetRequest } from "../utils/cmsRequests";
import { setToken } from "../utils/authenticator";
import ls from "local-storage";

const endpoint = process.env.REACT_APP_CMS_BASE_URL;
const headers = new Headers();
headers.append("Content-Type", "application/json");

export const login = async (email, password) => {
  const raw = JSON.stringify({ username: email, password: password });

  const response = await cmsPostRequest(`${endpoint}api/auth/token-auth/`, raw);
  const result = await response.json();
  if (result.token) {
    setToken("userToken", result.token);
    await getUserPreferences(result.token);
  }
  return response;
};

const getUserPreferences = async (token) => {
  const response = await cmsGetRequest(
    `${endpoint}api/unit_preferences/`,
    token
  );

  const responseJson = await response.json();
  if (responseJson[0].temperature_unit === "°F") {
    ls.set("temperature_unit", "°F");
  } else {
    ls.set("temperature_unit", "°C");
  }

  switch (responseJson[0].weight_unit) {
    case "st":
      ls.set("weight_unit", "st");
      break;
    case "lb":
      ls.set("weight_unit", "lb");
      break;
    default:
      ls.set("weight_unit", "kg");
      break;
  }

  if (responseJson[0].blood_glucose_unit === "mg/dL") {
    ls.set("blood_glucose_unit", "mg/dL");
  } else {
    ls.set("blood_glucose_unit", "mmol/L");
  }

  if (responseJson[0].flow_rate_unit === "L/sec") {
    ls.set("flow_rate_unit", "L/sec");
  } else {
    ls.set("flow_rate_unit", "L/min");
  }
};

export const postUserPreferences = async (value, token) => {
  const raw = JSON.stringify(value);

  if (value.temperature_unit) {
    ls.set("temperature_unit", value.temperature_unit);
  }

  if (value.weight_unit) {
    ls.set("weight_unit", value.weight_unit);
  }

  if (value.blood_glucose_unit) {
    ls.set("blood_glucose_unit", value.blood_glucose_unit);
  }

  if (value.flow_rate_unit) {
    ls.set("flow_rate_unit", value.flow_rate_unit);
  }

  const response = await cmsPostRequest(
    `${endpoint}api/unit_preferences/`,
    raw,
    token
  );
  return response;
};
