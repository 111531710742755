import moment from 'moment';
import { cmsGetRequest, cmsPostRequest, cmsPatchRequest, cmsPutRequest } from '../utils/cmsRequests';

const endpoint = process.env.REACT_APP_CMS_BASE_URL;

export const getPatients = async (token, returnFields) => {
    const prepReturnFields = (data) => {
        if(!data) {
            return '';
        }

        let fieldString = ''

        data.forEach((element, index) => {
            if(index === 0) {
                fieldString = fieldString.concat(`?return_fields=${element}`)
            } else {
                fieldString = fieldString.concat(`&return_fields=${element}`)
            }
        })

        return fieldString;
    };

    const returnFieldsString = prepReturnFields(returnFields);

    const result = await cmsGetRequest(`${endpoint}monitoring-portal/api/patients/${returnFieldsString}`, token);
    if (result.status !== 200) {
        return [];
    }
    return result.json();
}

export const getArchivedPatients = async (token) => {
    const result = await cmsGetRequest(`${endpoint}monitoring-portal/api/archived-patients/`, token);
    if (result.status !== 200) {
        return [];
    }
    return result.json();
}

export const getPatient = async (id, token, archived) => {
    const result = await cmsGetRequest(`${endpoint}monitoring-portal/api/${archived ? 'archived-' : ''}patients/${id}/`, token);
    if (result.status !== 200) {
        return {};
    }
    return result.json();
}

export const getFullPatient = async (id, token) => {
    const result = await cmsGetRequest(`${endpoint}api/patient/${id}/`, token);
    if (result.status !== 200) {
        return false;
    }
    return result.json();
}

export const getPatientMeasurementMethods = async (patient) => {
    return([
        {
            label: 'Oxygen Saturation',
            measurements: {
                key:'pulse_ox',
                title: 'Oxygen Saturation'
            }
        },
        {
            label: 'Breathlessness',
            measurements: {
                key:'breathlessness',
                title: 'Breathlessness'
            }
        },
        {
            label: 'Temperature',
            measurements: {
                key:'temperature',
                title: 'Temperature'
            }
        },
        {
            label: 'Heart Rate',
            measurements: {
                key:'heart_rate',
                title: 'Heart Rate'
            }
        },
        
    ])
}

export const getPatientData = async (patient, measurement, token) => {
    const result = await cmsGetRequest(`${endpoint}api/patient_measurement/?patient_id=${patient}&field=${measurement}&ordering=-time`, token);
    return result.json();
}

export const postPatientMeasurement = async (patient, key, value, date, condition, token) => {
    const raw = JSON.stringify({
        "condition_name":condition,
        [key]:value,
        "source": "monitoring-portal",
        "location_type":"home",
        "time":date,
    });

    const response = await cmsPostRequest(`${endpoint}api/patient_measurement/?patient_id=${patient}`, raw, token);
    return(response);
}

export const getPatientStatusSummary = async (token) => {
    const result = await cmsGetRequest(`${endpoint}monitoring-portal/api/patient-status-summary`, token)
    return result.json();
}

export const getPatientFilters = async (token) => {
    const result = await cmsGetRequest(`${endpoint}monitoring-portal/api/patient_filter/`, token);
    return result.json();
}

export const applyPatientFilter = async (id, token) => {
    const result = await cmsGetRequest(`${endpoint}monitoring-portal/api/patient_filter/${id}/apply/`, token);
    if (result.status !== 200) {
        return [];
    }
    return result.json();
}

export const postLatestReviewEvent = async (patientId, notes, token) => {
    const raw = JSON.stringify({
        "patient":patientId,
        "notes":notes
    });

    const response = await cmsPostRequest(`${endpoint}hospital/api/patient-reviewed-events/`, raw, token);
    return(response);
}

export const getPatientReviewEvents = async (patientId, token) => {
    const response = await cmsGetRequest(`${endpoint}hospital/api/patient-reviewed-events/patient/?patient_id=${patientId}`, token);
    return(response.json());
}

export const getPatientStatusSummaryForHospital = async (token, hospitalId) => {
    const response = await cmsGetRequest(`${endpoint}monitoring-portal/api/patient-status-summary?hospital_id=${hospitalId}`, token)
    return (response.json());
}

export const applyPatientFilterForHospital = async (id, hospitalId, token) => {
    const result = await cmsGetRequest(`${endpoint}monitoring-portal/api/patient_filter/${id}/apply/?hospital_id=${hospitalId}`, token)
    if (result.status !== 200) {
        return [];
    }
    return result.json();
}

export const getPatientReport = async (token, fromDate, toDate, id, archived) => {
    const result = await cmsGetRequest(
        `${endpoint}monitoring-portal/api/${archived ? 'archived-' : ''}patients/${id}/download_report?from_date=${fromDate}&to_date=${toDate}`,
        token
    );

    if (result.status === 200) {
        const blob = await result.blob()

        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Patient ${id} Report-${fromDate}to${toDate}.pdf`);

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);

        return null;
    }
    return null
}

export const getSpirometryDataForPatient = async (token, patientId) => {
    const result = await cmsGetRequest(
        `${endpoint}api/spirometry/?patient_id=${patientId}`,
        token
    );
    return result.json();
};

export const getFlowVolumeData = async (token, id) => {
    const result = await cmsGetRequest(
        `${endpoint}api/spirometry/${id}/volume_flow/`,
        token
    );
    return result.json();
}

export const getVolumeTimeData = async (token, id) => {
    const result = await cmsGetRequest(
        `${endpoint}api/spirometry/${id}/time_volume/`,
        token
    );
    return result.json();
}

export const unpublishPatientReview = async (token, id) => {
    const raw = JSON.stringify({"publish":false});

    const response = await cmsPatchRequest(
        `${endpoint}hospital/api/patient-reviewed-events/${id}/unpublish/`,
        raw,
        token
    );
    return response
};

export const editPatientReview = async (token, id, notes, patientId) => {
    const raw = JSON.stringify({
        "patient": patientId,
        "time": moment().format(),
        "notes": notes,
    });

    const response = await cmsPutRequest(
        `${endpoint}api/services/patients/reviews/${id}/`,
        raw,
        token
    )
    return response;
}

export const getPatientsMetadata = async (token) => {
    const result = await cmsGetRequest(
        `${endpoint}monitoring-portal/api/patients/metadata/`,
        token
    )
    if(result.status === 404) {
        return [];
    }
    return result.json();
}
