// Polyfills for IE11
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "fast-text-encoding/text";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "@material-ui/core";
import { Theme } from "./theme";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import { IntercomProvider } from "react-use-intercom";
import { Mixpanel } from "./mixpanel";

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

const handleIntercomChange = (action) => {
  switch (action) {
    case "show":
      Mixpanel.track("Opened Intercom messenger");
      break;
    case "hide":
      Mixpanel.track("Closed Intercom messenger");
      break;
    default:
      break;
  }
};

ReactDOM.render(
  <ThemeProvider theme={Theme}>
    {INTERCOM_APP_ID ? (
      <Router>
        <IntercomProvider
          appId={INTERCOM_APP_ID}
          onShow={() => {
            handleIntercomChange("show");
          }}
          onHide={() => {
            handleIntercomChange("hide");
          }}
        >
          <ScrollToTop />
          <App />
        </IntercomProvider>
      </Router>
    ) : (
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    )}
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
