import React, {useEffect} from 'react';
import { PatientsTable } from 'ui-components';
import { getPatients, applyPatientFilter, getArchivedPatients, getPatientsMetadata } from '../api/patientsApi';
import { prepTableData, addSortingAndFiltering, prepReturnFields, prepDisplayColumns } from '../utils/pageOperations/listPatientsPageOperations';
import { getToken } from '../utils/authenticator';
import { Redirect, useLocation, useHistory } from 'react-router-dom';

const defaultColumns = [
    { title: 'ID', field: 'id'},
    { title: 'Name', field: 'displayName'},
    { title: 'Hospital ID', field: 'hospitalPatientId'},
    { title: 'Sign-up Date', field: 'signUpDate'},
    { title: 'Last Used', field: 'lastUsedFromNow'},
    { title: 'Monitoring End Date', field: 'monitoringEndDateDisplay'},
    { title: 'Last Reviewed', field: 'lastReviewed'},
    { title: 'Last Reviewed By', field: 'lastReviewedBy'},
];

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function PatientsTableContainer(props) {
    let query = new useQuery();
    
    const [patients, setPatients] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const pageNum = query.get("page") ? parseInt(query.get("page")) : 0;  // Default to 0 
    const [page, setPage] = React.useState(pageNum);   
    const numberRows = query.get("numRows") ? parseInt(query.get("numRows")) : 20;  // Default to 20
    const [numRows, setNumRows] = React.useState(numberRows);
    const [redirect, setRedirect] = React.useState(false);
    const [displayColumns, setDisplayColumns] = React.useState([]);
    const [returnFields, setReturnFields] = React.useState(null);

    const history = useHistory();

    const { filter = null } = props;
    const { archived = false } = props; 

    const onLinkClick = (rowData, sortedData) => {
        props.onLinkClick(rowData, sortedData);
    };

    const handleChangePage = (page) => {
        setPage(page);
        setRedirect(true);
    }

    const handleNewPageSize = (newPageSize) => {
        setNumRows(newPageSize); // No redirect needed here as changing pageSize triggers a change page event.
    }

    useEffect(() => {
        let isMounted = true; // flag for component mount status
        const getDisplayColumnsFromApi = async () => {
            const result = await getPatientsMetadata(getToken());
            if (isMounted){ // Only run if this component is still mounted
                if(result.display_columns){
                    setDisplayColumns(addSortingAndFiltering(prepDisplayColumns(result.display_columns)));
                    setReturnFields(prepReturnFields(result.display_columns))
                } else {
                    setDisplayColumns(addSortingAndFiltering(defaultColumns));
                    setReturnFields([]);
                }
            }
        };

        getDisplayColumnsFromApi();
        return function cleanup() {
            isMounted = false; // function called when the component is unmounted
        };
    }, []);

    useEffect(() => {
        let isMounted = true; // flag for component mount status
        const getPatientsFromApi = async () => {
            setLoading(true);
            const result = archived ? await getArchivedPatients(getToken(), returnFields) : await getPatients(getToken(), returnFields);
            if (isMounted){ // Only run if this component is still mounted
                setPatients(result);
                setLoading(false);
            }
        };

        const applyNewPatientFilter = async () => {
            setLoading(true);
            const result = await applyPatientFilter(filter, getToken());
            if (isMounted){ // Only run if this component is still mounted
                setPatients(result);
                setLoading(false);
            }
        };
        
        if(filter){
            applyNewPatientFilter();
        } else {
            if(returnFields !== null) {
                getPatientsFromApi();
            }
        }
        return function cleanup() {
            isMounted = false; // function called when the component is unmounted
        };
    }, [filter, archived, returnFields]);

    useEffect(() => {
        setTableData(prepTableData(patients));
    }, [patients]);


   useEffect(() => {
      return history.listen((location) => { 
        const searchParams = new URLSearchParams(location.search);
        setPage(searchParams.get("page") ? parseInt(searchParams.get("page")) : 0);
        setNumRows(searchParams.get("numRows") ? parseInt(searchParams.get("numRows")) : 20);
        
        setLoading(true);  
        setLoading(false);  // Reload page to trigger an update in the table
      }) 
   },[history]) 


   const renderRedirect = () => {       
        if (redirect){
            return <Redirect
                push
                to={{
                    pathname: `/${props.archived ? 'archived-' : ''}patients`,
                    search: `?page=${page}&numRows=${numRows}`
                }}
            />
        }
    };   

    return (
        <>
            {renderRedirect()}
            <PatientsTable 
                title={'Patients'}
                data={tableData}
                onClick={onLinkClick}
                loading={loading}
                columns={displayColumns}
                includeSearch
                clickable
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleNewPageSize}
                initialPage={page}
                pageSizeOptions={[20, 40, 80]}
                numRows={numRows}
            />
        </>
    )
}
