import React, { useEffect } from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SingleDatePicker, SpirometryGraph } from 'ui-components'
import StatisticCardTable from 'ui-components/dist/StatisticCardTable/StatisticCardTable';
import { getFlowVolumeData, getSpirometryDataForPatient, getVolumeTimeData } from '../api/patientsApi';
import { getToken } from '../utils/authenticator';
import { prepAllowedDates, prepSpirometryData, prepDataForNewDate, prepFlowVolumePointsOfInterest } from '../utils/pageOperations/patientPageOperations';
import moment from 'moment';
import ls from 'local-storage'

const useStyles = makeStyles({
    title: {
        paddingTop: 24,
        paddingBottom: 16
    },
    spirometryTitle: {
        fontSize: 24
    },
    spirometryContainer: {
        paddingTop: 24
    },
});

export default function SpirometryDataContainer(props) {
    const classes = useStyles();
    const { patientId = null} = props;

    const [date, setDate] = React.useState(moment());
    const [lastDate, setLastDate] = React.useState(moment().add(1, 'days'));
    const [loading, setLoading] = React.useState(true);
    const [allowedDates, setAllowedDates] = React.useState();
    const [spirometryMeasurements, setSpirometryMeasurements] = React.useState([]);
    const [spirometryMeasurementsForDate, setSpirometryMeasurementsForDate] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [graphs, setGraphs] = React.useState([])

    const onChangeDate = (newDate) => {
        if (moment(date.format("Y-M-D")).isSame(moment(newDate.format("Y-M-D")))) {
            // Return if the same date has been selected again as the data is the same
            return;
        }

        setSpirometryMeasurementsForDate(prepDataForNewDate(newDate, spirometryMeasurements));
        setTableData(prepSpirometryData(prepDataForNewDate(newDate, spirometryMeasurements)));
        setDate(newDate);
    }

    useEffect(() => {
        let isMounted = true; // flag for component mount status
        const getSpirometryDataForPatientFromApi = async () => {
            const result = await getSpirometryDataForPatient(getToken(), patientId);

            if (isMounted){ // Only run if this component is still mounted
                setAllowedDates(prepAllowedDates(result))

                setSpirometryMeasurements(result);
                setSpirometryMeasurementsForDate(prepDataForNewDate(moment(), result));
                setTableData(prepSpirometryData(prepDataForNewDate(moment(), result)));
                setLoading(false);
            }
        }

        getSpirometryDataForPatientFromApi();

        return function cleanup() {
            isMounted = false; // function called when the component is unmounted
        };
    }, [patientId]);

    useEffect(() => {
        const selectedUnit = ls.get("flow_rate_unit");

        const getSpirometryGraphLabelBySelectedUnit = (selectedUnit) => {
            if (selectedUnit === 'L/min') {
                return 'L/min';
            } 

            return 'L/s';
        };

        const prepGraphs = () => {
            const graphContainers = [];
    
            spirometryMeasurementsForDate.forEach((element) => {
                graphContainers.push(<Grid container>
                    <Grid item xs={6}>
                        <SpirometryGraph 
                            data={element.flowVolumeCurveData}
                            xRange={[0,6]}
                            yRange={[0, element.flowVolumeRange]}
                            axisLabels={['Vol (L)', `Flow (${getSpirometryGraphLabelBySelectedUnit(selectedUnit)})`]}
                            pointsOfInterest={element.flowVolumePointsOfInterest || []}
                            interpolation="linear"
                            time={moment(element.start_time).format('h:mma')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SpirometryGraph
                            data={element.volumeTimeCurveData}
                            xRange={[0,6]}
                            yRange={[0, element.volumeTimeRange]}
                            axisLabels={['Time (s)','Vol (L)']}
                            pointsOfInterest={element.volumeTimePointsOfInterest || []}
                            interpolation="linear"
                            time={moment(element.start_time).format('h:mma')}
                        />
                    </Grid>
                </Grid>);
            });
            
            return(graphContainers);
        };

        const getFlowVolumeDataFromApi = async (measurement, index) => {
            const getVolumeRangebySelectedUnit = (pefData, selectedUnit) => {
                if(selectedUnit === "L/min") {
                    return parseFloat(pefData * 60) + 100;
                }

                return parseFloat(pefData) + 1;
            };

            const prepFlowVolumeDataBySelectedUnit = (flowVolumeData, selectedUnit) => {
                if(selectedUnit === "L/min") {
                    return flowVolumeData.map(currentFlowVolumeData => ({ x: currentFlowVolumeData.x, y: currentFlowVolumeData.y * 60 }));
                }

                return flowVolumeData;
            };

            let result = await getFlowVolumeData(getToken(), measurement.id);
            result = prepFlowVolumeDataBySelectedUnit(result, selectedUnit);

            const temp = [...spirometryMeasurementsForDate];

            measurement.flowVolumePointsOfInterest = prepFlowVolumePointsOfInterest(measurement, result);
            measurement.flowVolumeRange = getVolumeRangebySelectedUnit(measurement.pef, selectedUnit);
            measurement.flowVolumeCurveData = result;

            temp[index] = measurement;

            setSpirometryMeasurementsForDate(temp);
        };

        const getVolumeTimeDataFromApi = async (measurement, index) => {
            const result = await getVolumeTimeData(getToken(), measurement.id);

            const temp = [...spirometryMeasurementsForDate];

            // Due to inaccuracies in calculating the "zero" mark for FEV1, we are temporarily 
            // removing the points of interest (FEV1 and FVC labels) from the Volume/Time graph
            measurement.volumeTimePointsOfInterest = [];
            measurement.volumeTimeRange = parseFloat(measurement.fvc) + 1;
            measurement.volumeTimeCurveData = result;

            temp[index] = measurement;

            setSpirometryMeasurementsForDate(temp);
        }

        if(spirometryMeasurementsForDate.length > 0 && date.format('DD-MM-YYYY') !== lastDate.format('DD-MM-YYYY')){
            spirometryMeasurementsForDate.forEach((element, index) => {
                getFlowVolumeDataFromApi(element, index);
                getVolumeTimeDataFromApi(element, index);
            });

            setLastDate(date);
        }

        setGraphs(prepGraphs);
        
    }, [spirometryMeasurementsForDate, date, lastDate]);

    return (
        <>
        {!loading
            ? <Grid item xs={12} className={classes.spirometryContainer}>
                <Card>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} className={classes.title}>
                                <Typography align='center' variant='h4' className={classes.spirometryTitle}>Spirometry Readings</Typography>
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <SingleDatePicker
                                    date={date}
                                    onDateChange={onChangeDate}
                                    allowedDates={allowedDates}
                                />
                            </Grid>
                        </Grid>
                        {graphs}
                    </CardContent>
                </Card>
                <StatisticCardTable 
                    headCells={{
                        'FEV1': 'FEV₁',
                        'FVC': 'FVC',
                        'FEV1 / FVC': 'FEV₁ / FVC',
                        'PEF': 'PEF',
                        'Date': 'Date',
                        'Time': 'Time',
                    }}
                    data={tableData}
                />
            </Grid>
            : <Grid item xs={12} className={classes.title}>
                <Typography align='center' variant='h4'>Spirometry Readings</Typography>
            </Grid>
        }
        </>
    )
}