import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Mixpanel } from '../mixpanel';
import { Redirect } from 'react-router-dom';
import { getSortedTableData } from '../utils/pageOperations/listPatientsPageOperations';
import PatientsTableContainer from '../containers/PatientsTableContainer';
import UserProfileLabelContainer from '../containers/UserProfileLabelContainer';
import { useIntercom } from 'react-use-intercom';

const useStyles = makeStyles({
    background: {
        backgroundColor: '#f3f5fb',
        alignItems: 'center',
    },
    gridContainer: {
        maxWidth: 1280,
        margin: 'auto',
        paddingLeft: 72,
    },
    header: {
        paddingTop: 24,
    }
});

export default function ArchivedPatientsPage(props) {
    const classes = useStyles();

    const { user } = props;

    const [navigateToPage, setNavigateToPage] = React.useState(false);
    const [clickedPatient, setClickedPatient] = React.useState(null);
    const [sortedTableData, setSortedTableData] = React.useState(null);
    const { update } = useIntercom();

    const onLinkClick = (rowData, sortedData) => {
        setSortedTableData(getSortedTableData(sortedData, 'id', rowData.id));
        setClickedPatient(rowData.id);
    };

    useEffect(() => { 
        Mixpanel.track('Opened Archived Patients List Page');
    }, []);

    useEffect(() => {
        if(process.env.REACT_APP_INTERCOM_APP_ID){
            const intercomUpdateWithProps = ((result) => update({
                userId: result.user,
                last_request_at: parseInt((new Date()).getTime()/1000)
            }));

            
            intercomUpdateWithProps(user);
        }
    },[update, user]);

    useEffect(() => {
        if(sortedTableData){
            setNavigateToPage(true);
        }
    }, [sortedTableData]);

    return (
        <div className={classes.background}>
            { navigateToPage 
                ? <Redirect 
                    push
                    to={{
                        pathname:`/archived-patients/${clickedPatient}`,
                        state: {
                            sortedTableData: sortedTableData,
                        }
                    }}
                /> 
                : null 
            }
            <Grid container className={classes.header}></Grid>
            <Grid container spacing={1} className={classes.gridContainer}>
                <Grid item xs={12}>
                    <UserProfileLabelContainer />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3">Archived Patients</Typography>
                </Grid>
                 <Grid item xs={12}>
                    <PatientsTableContainer
                        onLinkClick={onLinkClick}
                        archived
                    />
                </Grid>
            </Grid>
        </div>
    )
} 
