import React from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Mixpanel } from '../mixpanel';

export default function AddMeasurementButtonContainer(props) {
    const handleAddMeasurementOpen = () => {
        Mixpanel.track('Add Measurement button clicked');
        props.handleAddMeasurementOpen(true);
    };

    return (
        <Button 
            variant="outlined" 
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleAddMeasurementOpen}
        >
            Add measurement
        </Button>
    )
}