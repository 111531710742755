import { Button, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { SubNavTabs } from 'ui-components';
import TriggeredAlertsTableContainer from './TriggeredAlertsTableContainer';
import AddIcon from '@material-ui/icons/Add';
import Toast from 'ui-components/dist/Toast/Toast';
import CreateAlertModalContainer from './CreateAlertModalContainer';
import { Mixpanel } from '../mixpanel';
import ConfiguredAlertsTableContainer from './ConfigureAlertsTableContainer';

const useStyles = makeStyles((theme) => ({
    subNavContainer: {
        paddingTop: 38,
        paddingBottom: 16
    },
    tableContainer: {
        paddingTop: 8,
        paddingBottom: 16
    },
    createAlertButtonContainer: {
        paddingTop: 38,
        paddingBottom: 16
    },
    createAlertButton: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingRight: 26,
        paddingLeft: 21
    },
    createAlertButtonLabel: {
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '0.75px',
        lineHeight: '16px'
    },
    createAlertButtonIcon: {
        fontSize: 24,
        '&:first-child': {
            fontSize: 24,
        }
    }
}));

export default function AlertsTabContainer(props) {
    const classes = useStyles();

    const {
        patientId,
        conditionType,
        onChangeResolvedAlerts
    } = props;

    const [tabIndex, setTabIndex] = React.useState(0);
    const [resolvedToastOpen, setResolvedToastOpen] = React.useState(false);
    const [errorToastOpen, setErrorToastOpen] = React.useState(false);
    const [createdToastOpen, setCreatedToastOpen] = React.useState(false);
    const [deletedToastOpen, setDeletedToastOpen] = React.useState(false);
    const [editedToastOpen, setEditedToastOpen] = React.useState(false);
    const [createAlertModalOpen, setCreateAlertModalOpen] = React.useState(false);
    const [refetchAlerts, setRefetchAlerts] = React.useState(0)

    const tabs = [
        {
            label: "Triggered alerts",
            tooltip: "View alerts that have been triggered by patients"
        },{
            label: "Configure alerts",
            tooltip: "Manage your scheduled alerts"
        }
    ];

    const handleTabChange = (tab) => {
        switch(tab) {
            case 0:
                Mixpanel.track('Alerts Tab: Triggered Alerts sub nav tab');
                break;
            case 1:
                Mixpanel.track('Alerts Tab: Configured Alerts sub nav tab');
                break;
            default:
                break;
        }
        setTabIndex(tab);
    };
    
    const handleCreateNewAlertClick = () => {
        Mixpanel.track('Alerts Tab: Create Alert Modal ');
        setCreateAlertModalOpen(true);
    };

    const handleCloseCreateNewAlertModal = () => {
        Mixpanel.track('Alerts Tab: Create Alert Modal close');
        setCreateAlertModalOpen(false);
    };

    const handleCancelCreateNewAlertModal = () => {
        Mixpanel.track('Alerts Tab: Create Alert Modal cancel');
        setCreateAlertModalOpen(false);
    };

    const getActiveTab = () => {
        switch(tabIndex) {
            case 0: 
                return <TriggeredAlertsTableContainer
                    patientId={patientId}
                    openToast={openToast}
                    onChangeResolvedAlerts={onChangeResolvedAlerts}
                />;
            case 1: 
                return <ConfiguredAlertsTableContainer
                    patientId={patientId}
                    openToast={openToast}
                    refetchAlerts={refetchAlerts}
                    conditionType={conditionType}
                />;
            default:
                return <TriggeredAlertsTableContainer
                    patientId={patientId}
                    openToast={openToast}
                    onChangeResolvedAlerts={onChangeResolvedAlerts}
                />;
        }
    }

    const openToast = (toast) => {
        switch(toast) {
            case 'resolve':
                setResolvedToastOpen(true);
                break;
            case 'create':
                setCreatedToastOpen(true);
                break;
            case 'delete':
                setDeletedToastOpen(true);
                break;
            case 'edit':
                setEditedToastOpen(true);
                break;
            case 'error':
                setErrorToastOpen(true);
                break;
            default:
                break;
        }
    }

    const handleCreateAlert = (status) => {
        if(status === 201) {
            setCreateAlertModalOpen(false);
            setRefetchAlerts(refetchAlerts + 1);
            openToast('create');
        } else {
            openToast('error');
        }
    }

    return (
        <>
            <Grid container>
                <Grid item xs={6} className={classes.subNavContainer}>
                    <SubNavTabs 
                        tabs={tabs}
                        onChange={handleTabChange}
                    />
                </Grid>
                <Grid item xs={6} className={classes.createAlertButtonContainer} align="right">
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<AddIcon className={classes.createAlertButtonIcon}/>}
                        classes={{
                            root: classes.createAlertButton,
                            label: classes.createAlertButtonLabel
                        }}
                        onClick={handleCreateNewAlertClick}
                    >
                        Create new alert
                    </Button>
                </Grid>
                <Grid item xs={12} className={classes.tableContainer}>
                    {
                        getActiveTab()
                    }
                </Grid>
            </Grid>
            <Toast open={resolvedToastOpen} onClose={()=>{setResolvedToastOpen(false)}} message="Alert resolved."/>
            <Toast open={createdToastOpen} onClose={()=>{setCreatedToastOpen(false)}} message="Alert created."/>
            <Toast open={deletedToastOpen} onClose={()=>{setDeletedToastOpen(false)}} message="Alert deleted."/>
            <Toast open={editedToastOpen} onClose={()=>{setEditedToastOpen(false)}} message="Alert edited."/>
            <Toast open={errorToastOpen} onClose={()=>{setErrorToastOpen(false)}} message="Something went wrong" error/>
            <CreateAlertModalContainer
                patientId={patientId}
                open={createAlertModalOpen}
                handleClose={handleCloseCreateNewAlertModal}
                handleCancel={handleCancelCreateNewAlertModal}
                conditionType={conditionType}
                onSubmit={handleCreateAlert}
            />
        </>
    )
}