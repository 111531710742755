import React, { useEffect } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getLoggedInHospitalStaff } from '../api/hospitalApi';
import { getToken } from '../utils/authenticator';

const useStyles = makeStyles({
    loadingLabel: {
        maxWidth: 160,
    },
});

export default function UserProfileLabelContainer() {
    const classes = useStyles();
    const [userProfile, setUserProfile] = React.useState({});
    const [userProfileLoading, setUserProfileLoading] = React.useState(true);

    useEffect(() => {
        let isMounted = true; // flag for component mount status
        const getUserProfileFromApi = async () => {
            const result = await getLoggedInHospitalStaff(getToken());
            if (isMounted){ // Only run if this component is still mounted
                setUserProfile(result);
                setUserProfileLoading(false);
            }
        }
        getUserProfileFromApi();

        return function cleanup() {
            isMounted = false; // function called when the component is unmounted
        };
    }, []);

    return (
        userProfileLoading
            ? <Skeleton className={classes.loadingLabel}/>
            : <Typography variant="h5">
                {
                    userProfile.hospital 
                    ? userProfile.hospital.name 
                    : 'patientMpower'
                } Portal
            </Typography>
    )
}