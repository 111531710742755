import { cmsGetRequest } from '../utils/cmsRequests';

const endpoint = process.env.REACT_APP_CMS_BASE_URL;

export const getLoggedInHospitalStaff = async (token) => {
    const result = await cmsGetRequest(`${endpoint}monitoring-portal/api/hospital-staff/me/`, token);
    if (result.status !== 200) {
        return {};
    }
    return result.json();
};

export const getHospitalsForUser = async (token) => {
    const result = await cmsGetRequest(`${endpoint}monitoring-portal/api/hospitals/`, token);
    if (result.status !== 200) {
        return [];
    }
    return result.json();
}

export const getHospitalReport = async (token, fromDate, toDate, hospitalId, includePatients) => {
    const result = await cmsGetRequest(
        `${endpoint}monitoring-portal/api/hospital-report/pdf?from_date=${fromDate}&to_date=${toDate}${hospitalId ? '&hospital_id=' + hospitalId : ''}${includePatients === true ? '&list_active_patients=true' : ''}`,
        token
    );

    if (result.status === 200) {
        const blob = await result.blob()

        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `HospitalReport-${fromDate}to${toDate}.pdf`);

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);

        return null;
    }
    return null
}