import React from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    container: {
        paddingBottom: 8,
    },
    picker: {
        marginLeft: 16,
    }
});

export default function DateRangeSelectorContainer(props) {
    const classes = useStyles();

    const { startDate = moment.subtract(2, 'weeks') } = props;
    const { endDate = moment() } = props;

    const { handleStartDateChange = () => {} } = props;
    const { handleEndDateChange = () => {} } = props;

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} align="right">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker 
                        value={startDate} 
                        onChange={handleStartDateChange}
                        disableFuture
                        label="Start date"
                        className={classes.picker}
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker 
                        value={endDate} 
                        onChange={handleEndDateChange}
                        disableFuture
                        label="End date"
                        className={classes.picker}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
        </Grid>
    )
}