import moment from 'moment';
import 'moment-timezone';

const cmsRequest = async (url, method, token, headers, body) => {
    if (headers === undefined || headers === null) {
        headers = new Headers();
    }

    if (token !== undefined && token !== null) {
        headers.append("Authorization", `Token ${token}`);
    }

    headers.append("Content-Type", "application/json");
    headers.append("TIMEZONE", moment.tz.guess());

    const requestOptions = {
        method: method,
        headers: headers,
        body: body,
        redirect: 'follow'
    };

    const result = await fetch(url, requestOptions);

    if(result.status === 401) {
        window.location.assign(`/login`);
    } else {
        return result;
    }
}

export const cmsGetRequest = (url, token, headers) => {
    return cmsRequest(url, 'GET', token, headers, null);
}

export const cmsPostRequest = (url, data, token, headers) => {
    return cmsRequest(url, 'POST', token, headers, data);
}

export const cmsPatchRequest = (url, data, token, headers) => {
    return cmsRequest(url, 'PATCH', token, headers, data);
}

export const cmsPutRequest = (url, data, token, headers) => {
    return cmsRequest(url, 'PUT', token, headers, data);
}
