import React from 'react';
import moment from 'moment';
import { Chip } from '@material-ui/core';

export const getSortedTableData = (data, field, selectedRow) => {
    const sortedData = data.map((value) => {
        return value[field]
    })

    const getIndex = (data, field) => {
        let newIndex;

        data.forEach((element, index) => {
            if(element === field) {
                newIndex = index;
            }
        })

        return newIndex;
    }

    return {
        data: sortedData,
        index: getIndex(sortedData, selectedRow),
        length: sortedData.length,
    }
};

export const prepTableData = (data) => {
    const result = [];

    data.forEach((element) => {
        const getLastUsedDate = () => {
            if(element.last_used){
                return moment(element.last_used);
            } else {
                return null;
            }
        }

        const getLastUsedDateFromNow = () => {
            const date = getLastUsedDate();

            if(date === null){
                return '-';
            } else {
                return date.fromNow();
            }
        }

        const getLastReviewedDate = () => {
            if(element.latest_review_event) {
                return moment(element.latest_review_event.time).fromNow();
            } else {
                return '-';
            }
        }

        const getLastReviewedTime = () => {
            if(element.latest_review_event) {
                return moment(element.latest_review_event.time)
            } else {
                return null;
            }
        }

        const getLastReviewedBy = () => {
            if(element.latest_review_event) {
                return element.latest_review_event.reviewed_by_name;
            } else {
                return '-';
            }
        }

        const getCreated = () => {
            if(element.created){
                return moment(element.created);
            } else {
                return null;
            }
        }

        const getSignUpDate = () => {
            const date = getCreated();

            if(date === null){
                return '-';
            } else {
                return date.format('MMM Do, Y, hh:mm a');
            }
        }

        const getMonitoringEndDate = () => {
            if(element.monitoring_end_date){
                return moment(element.monitoring_end_date);
            } else {
                return null;
            }
        }

        const getMonitoringEndDateDisplay = () => {
            const date = getMonitoringEndDate();

            if(date === null){
                return '-';
            } else {
                return date.format('MMM Do, Y, hh:mm a');
            }
        }

        const getSurveyResults = () => {
            if(element.last_wellness_report_result){
                return element.last_wellness_report_result
            }

            return '-'
        }

        const getSurveyReceivedDateTime = () => {
            if(element.last_wellness_report_timestamp){
                return moment(element.last_wellness_report_timestamp)
            } else {
                return null;
            }
        }

        const getSurveyReceivedDateTimeDisplay = () => {
            const date = getSurveyReceivedDateTime();

            if(date === null){
                return '-';
            } else {
                return date.format('MMM Do, Y, hh:mm a');
            }
        }

        let row = {
            id: element.id, 
            name: element.name,
            displayName: <>{element.name} {element.is_staff ? <Chip size="small" label="Staff" color="primary"/> : ''}</>,
            hospitalPatientId: element.hospital_patient_id || '-',
            created: getCreated(),
            signUpDate: getSignUpDate(),
            lastUsed: getLastUsedDate(),
            lastUsedFromNow: getLastUsedDateFromNow(),
            monitoringEndDate: getMonitoringEndDate(),
            monitoringEndDateDisplay: getMonitoringEndDateDisplay(),
            lastReviewed: getLastReviewedDate(),
            lastReviewedTime: getLastReviewedTime(),
            lastReviewedBy: getLastReviewedBy(),
            surveyResults: getSurveyResults(),
            surveyReceived: getSurveyReceivedDateTime(),
            surveyReceivedDisplay: getSurveyReceivedDateTimeDisplay(),
        };

        if ('last_name' in element) {
            row.lastName = element.last_name;
        }

        if ('first_name' in element) {
            row.firstName = element.first_name;
        }

        result.push(row);
    });

    return result;
};

export const customSortName = (a, b) => {
    if (!('lastName' in a)) {
        return customSortStrings(a.name, b.name)
    } else {
        let result = customSortStrings(a.lastName, b.lastName);

        if (result === 0) {
            result = customSortStrings(a.firstName, b.firstName);
        }

        return result;
    }
}

const customSortStrings = (a, b) => {
    if (a !== b) {
        if (!a) { 
            return -1;
        }
        if (!b) {
            return 1;
        }
    }
    
    let result = 0;

    if (a.toLowerCase() < b.toLowerCase()) {
        result = -1;
    } else if (a.toLowerCase() > b.toLowerCase()) {
        result = 1;
    } 

    return result;
};

export const addSortingAndFiltering = (data) => {
    return data.map((element) => {
        const newElement = element;
        
        switch(newElement.field) {
            case 'displayName':
                newElement.customSort = (a, b) => {
                    return customSortName(a, b)
                };

                newElement.customFilterAndSearch = (a, b, c) => {
                    const searchTerm = a.toLowerCase();
                    const rowData = b.name.toLowerCase();
                    return rowData.indexOf(searchTerm) > -1;
                };
                break;
            case 'signUpDate':
                newElement.customSort = (a, b) => b.created - a.created;
                break;
            case 'lastUsedFromNow':
                newElement.customSort = (a, b) => b.lastUsed - a.lastUsed;
                break;
            case 'lastReviewed': 
                newElement.customSort = (a, b) => b.lastReviewedTime - a.lastReviewedTime;
                break;
            case 'monitoringEndDateDisplay':
                newElement.customSort = (a, b) => b.monitoringEndDate - a.monitoringEndDate;
                break;
            case 'surveyReceivedDisplay':
                newElement.customSort = (a, b) => b.surveyReceived - a.surveyReceived;
                break;
            default:
                break
        }

        return newElement
    });
}

export const prepReturnFields = (data) => {
    if(data.length < 1){
        return [];
    }
    
    const returnFields = ['id'];

    data.forEach((element) => {
        switch(element.field) {
            case 'id':
                if(!returnFields.includes('id')) {
                    returnFields.push('id');
                }
                break;
            case 'name':
                if(!returnFields.includes('name')) {
                    returnFields.push('name');
                }
                if(!returnFields.includes('first_name')) {
                    returnFields.push('first_name');
                }
                if(!returnFields.includes('last_name')) {
                    returnFields.push('last_name'); 
                }
                break;
            case 'hospital_patient_id':
                if(!returnFields.includes('hospital_patient_id')){
                    returnFields.push('hospital_patient_id');   
                }
                break;
            case 'sign_up_date':
                if(!returnFields.includes('created')){
                    returnFields.push('created');   
                }
                break;
            case 'last_used_from_now':
                if(!returnFields.includes('last_used')){
                    returnFields.push('last_used');   
                }
                break;
            case 'monitoring_end_date':
                if(!returnFields.includes('monitoring_end_date')){
                    returnFields.push('monitoring_end_date')
                }
                break;
            case 'last_reviewed':
                if(!returnFields.includes('latest_review_event')){
                    returnFields.push('latest_review_event');   
                }
                break;
            case 'last_reviewed_by':
                if(!returnFields.includes('latest_review_event')){
                    returnFields.push('latest_review_event');   
                }
                break;
            case 'wellness_report_survey_results':
                if(!returnFields.includes('last_wellness_report_result')){
                    returnFields.push('last_wellness_report_result');   
                }
                break;
            case 'wellness_report_survey_timestamp':
                if(!returnFields.includes('last_wellness_report_timestamp')){
                    returnFields.push('last_wellness_report_timestamp');   
                }
                break;
            default:
                break;
        }
    })

    return returnFields;
}

export const prepDisplayColumns = (data) => {
    const displayColumns = [];

    data.forEach((element) => {
        switch(element.field) {
            case 'id':
                displayColumns.push({
                    field: 'id',
                    title: 'ID',
                });
                break;
            case 'name':
                displayColumns.push({
                    field: 'displayName',
                    title: element.title,
                });
                break;
            case 'hospital_patient_id':
                displayColumns.push({
                    field: 'hospitalPatientId',
                    title: element.title,
                });
                break;
            case 'sign_up_date':
                displayColumns.push({
                    field: 'signUpDate',
                    title: element.title,
                });
                break;
            case 'last_used_from_now':
                displayColumns.push({
                    field: 'lastUsedFromNow',
                    title: element.title,
                });
                break;
            case 'monitoring_end_date':
                displayColumns.push({
                    title: element.title, 
                    field: 'monitoringEndDateDisplay'
                });
                break;
            case 'last_reviewed':
                displayColumns.push({
                    field: 'lastReviewed',
                    title: element.title,
                });
                break;
            case 'last_reviewed_by':
                displayColumns.push({
                    field: 'lastReviewedBy',
                    title: element.title,
                });
                break;
            case 'wellness_report_survey_results':
                displayColumns.push({
                    field: 'surveyResults',
                    title: element.title,
                    render: (rowData) => { 
                        const cellDataStyle = getSurveyCellDataStyle(rowData.surveyResults);

                        return <p style={cellDataStyle}>{rowData.surveyResults}</p> 
                    }
                });
                break;
            case 'wellness_report_survey_timestamp':
                displayColumns.push({
                    field: 'surveyReceivedDisplay',
                    title: element.title
                })
                break;
            default:
                break;
        }
    });

    return displayColumns;
}

export const getSurveyCellDataStyle = (result) => {
    if(result === 'Request a call' || result === 'Requires a call') {
        return {color: '#CB3B31'}
    } else if (result === 'Symptoms') {
        return {color: '#DA8221'}
    } else if (result === 'No symptoms') {
        return {color: '#186323'}
    }

    return {}
}
