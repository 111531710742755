import { createTheme } from '@material-ui/core/styles';

export const Theme = createTheme({
  palette: {
    primary: {
      light: '#6fcefe',
      main: '#25799D',
      dark: '#006f9a',
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#ff6e5b',
      main: '#CB3B31',
      dark: '#982C25',
    },
    // error: will use the default color
  },
});