import React, { useEffect } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, NativeSelect } from '@material-ui/core';
import { getHospitalsForUser } from '../api/hospitalApi';
import { prepMenuItems } from '../utils/pageOperations/dashboardPageOperations';
import { getToken } from '../utils/authenticator';

const useStyles = makeStyles({
    formControl: {
        minWidth: 200,
        alignItems: 'left',
        paddingTop: 4,
    },
    hospitalsLoadingLabel: {
        height: "40px",
        marginLeft: "10px",
        width: "120px",
    },
    hide: {
        display: 'none'
    }
});

export default function HospitalSelectContainer(props) {
    const classes = useStyles();
    const [hospitals, setHospitals] = React.useState([]);
    const [loadingHospitals, setLoadingHospitals] = React.useState(true);
    const [menuItems, setMenuItems] = React.useState([]);

    const handleChangeHospital = (event) => {
        props.handleChangeHospital(event.target.value)
    }

    const { updateHospitals } = props;

    useEffect(() => {
        let isMounted = true; // flag for component mount status
        const getHospitalsForUserFromApi = async () => {
            const result = await getHospitalsForUser(getToken());
            if (isMounted){ // Only run if this component is still mounted
                setHospitals(result);
                if(result.length !== hospitals.length) {
                    updateHospitals(result);
                }
                setMenuItems(prepMenuItems(result));
                setLoadingHospitals(false);
            }
        }
    
        getHospitalsForUserFromApi();

        return function cleanup() {
            isMounted = false; // function called when the component is unmounted
        };
    }, [updateHospitals, hospitals.length])
    
    return (
        loadingHospitals
            ? <Skeleton className={classes.hospitalsLoadingLabel}/>
            : <FormControl className={hospitals.length > 1 ? classes.formControl : classes.hide}>
                <InputLabel shrink htmlFor="select-hospital-label-placeholder" id="demo-simple-select-label">
                    Hospital
                </InputLabel>
                <NativeSelect
                    onChange={handleChangeHospital}
                    name="hospital"
                >
                    <option value={'all'}>All</option>
                    {
                        menuItems
                    }
                </NativeSelect>
            </FormControl>
    );
}