import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Mixpanel } from "../mixpanel";
import { useParams, useLocation, Redirect } from "react-router-dom";
import PatientDetailsContainer from "../containers/PatientDetailsContainer";
import DisplayNameContainer from "../containers/DisplayNameContainer";
import GeneratePatientReportButtonContainer from "../containers/GeneratePatientReportButtonContainer";
import NavigateBetweenPatientsContainer from "../containers/NavigateBetweenPatientsContainer";
import GeneratePatientReportDialogContainer from "../containers/GeneratePatientReportDialogContainer";
import VitalsTabs from "ui-components/dist/VitalsTabs/VitalsTabs";
import VitalsTabContainer from "../containers/VitalsTabContainer";
import ReviewsTabContainer from "../containers/ReviewsTabContainer";
import { VitalsIcon } from "ui-components";
import ReviewsIcon from "@material-ui/icons/Assignment";
import WarningIcon from "@material-ui/icons/Warning";
import { useIntercom } from "react-use-intercom";
import { getFullPatient } from "../api/patientsApi";
import { getToken } from "../utils/authenticator";
import AlertsTabContainer from "../containers/AlertsTabContainer";
import { getUnresolvedAlerts } from "../api/alertsApi";

const useStyles = makeStyles({
  background: {
    backgroundColor: "#f3f5fb",
    alignItems: "center",
  },
  tabBackground: {
    backgroundColor: "#ffffff",
    alignItems: "center",
  },
  tabContainer: {
    paddingTop: 24,
    paddingBottom: 0,
  },
  gridContainer: {
    maxWidth: 976,
    minWidth: 976,
    margin: "auto",
    paddingLeft: 72,
  },
  header: {
    paddingTop: 24,
  },
  addMeasurement: {
    paddingTop: 4,
  },
});

export default function PatientPage(props) {
  const classes = useStyles();
  let location = useLocation();
  let { patientId } = useParams();
  const { update } = useIntercom();
  const { user } = props;

  const [sortedTableData, setSortedTableData] = React.useState(null);
  const [navigateToPage, setNavigateToPage] = React.useState(false);
  const [refreshPatientDetails, setRefreshPatientDetails] = React.useState(0);
  const [generateReportOpen, setGenerateReportOpen] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [conditionType, setConditionType] = React.useState(null);
  const [patientNotFound, setPatientNotFound] = React.useState(false);
  const [unresolvedAlerts, setUnresolvedAlerts] = React.useState(null);
  const [refreshTabs, setRefreshTabs] = React.useState(0); // integer that can be incremented to re-trigger a useEffect()

  const getReviewsRowsPerPage = () => {
    if (location.state) {
      if (location.state.reviewsRowsPerPage) {
        return location.state.reviewsRowsPerPage;
      }
    }
    return 8;
  };

  const [reviewsRowsPerPage, setReviewsRowsPerPage] = React.useState(
    getReviewsRowsPerPage()
  );

  const onChangePage = (data) => {
    setSortedTableData(data);
  };

  const handleGenerateReportOpen = (result) => {
    setGenerateReportOpen(result);
  };

  const handleRefreshTabs = () => {
    setRefreshTabs(refreshTabs + 1);
  };

  const handleTabChange = (index) => {
    switch (index) {
      case 0:
        Mixpanel.track("Clicked Vitals tab");
        break;
      case 1:
        Mixpanel.track("Clicked Alerts tab");
        break;
      case 2:
        Mixpanel.track("Clicked Reviews");
        break;
      default:
        break;
    }

    handleRefreshTabs();
    setTabIndex(index);
  };

  const handleChangeReviewsRowsPerPage = (rpp) => {
    setReviewsRowsPerPage(rpp);
  };

  useEffect(() => {
    Mixpanel.track("Opened Patient Page");
  }, [location]);

  useEffect(() => {
    if (sortedTableData) {
      setNavigateToPage(true);
      setTabIndex(0);
    }
  }, [sortedTableData]);

  useEffect(() => {
    if (process.env.REACT_APP_INTERCOM_APP_ID) {
      const intercomUpdateWithProps = (result) =>
        update({
          userId: result.user,
          last_request_at: parseInt(new Date().getTime() / 1000),
        });

      intercomUpdateWithProps(user);
    }
  }, [update, user]);

  useEffect(() => {
    let isMounted = true; // flag for component mount status
    const getFullPatientFromApi = async () => {
      setConditionType(null);
      setLoading(true);
      const response = await getFullPatient(patientId, getToken());
      if (!response) {
        setPatientNotFound(true);
      }
      if (isMounted && response) {
        // Only run if this component is still mounted
        setConditionType(response.conditions[0].type.slug);
      }
      setLoading(false);
    };

    if (patientId) {
      getFullPatientFromApi();
    }

    return function cleanup() {
      isMounted = false; // function called when the component is unmounted
    };
  }, [patientId]);

  useEffect(() => {
    const getUnresolvedAlertsFromApi = async () => {
      const response = await getUnresolvedAlerts(patientId, getToken());

      if (response.count) {
        if (response.count > 9) {
          setUnresolvedAlerts("9+");
        } else {
          setUnresolvedAlerts(response.count);
        }
      } else {
        setUnresolvedAlerts(null);
      }
    };

    getUnresolvedAlertsFromApi();
  }, [refreshTabs, patientId]);

  const getActiveTab = () => {
    switch (tabIndex) {
      case 0:
        return (
          <VitalsTabContainer
            patientId={patientId}
            conditionType={conditionType}
          />
        );
      case 1:
        return (
          <AlertsTabContainer
            patientId={patientId}
            conditionType={conditionType}
            onChangeResolvedAlerts={handleRefreshTabs}
          />
        );
      case 2:
        return (
          <ReviewsTabContainer
            patientId={patientId}
            refreshReviews={() =>
              setRefreshPatientDetails(refreshPatientDetails + 1)
            }
            rowsPerPage={reviewsRowsPerPage}
            onChangeRowsPerPage={handleChangeReviewsRowsPerPage}
            user={user}
          />
        );
      default:
        return (
          <VitalsTabContainer
            patientId={patientId}
            conditionType={conditionType}
          />
        );
    }
  };

  return loading ? null : (
    <>
      <div className={classes.background}>
        {navigateToPage ? (
          <Redirect
            push
            to={{
              pathname: `/${props.archived ? "archived-" : ""}patients/${
                sortedTableData.data[sortedTableData.index]
              }`,
              state: {
                sortedTableData: sortedTableData,
                reviewsRowsPerPage: reviewsRowsPerPage,
              },
            }}
          />
        ) : null}
        {patientNotFound ? (
          <Redirect
            push
            to={{
              pathname: `/404`,
            }}
          />
        ) : null}
        <Grid container className={classes.header}></Grid>
        <Grid container spacing={1} className={classes.gridContainer}>
          <Grid item xs={6}>
            <DisplayNameContainer
              patientId={patientId}
              archived={props.archived}
            />
          </Grid>
          <Grid item xs={6} align="right">
            <GeneratePatientReportButtonContainer
              handleGenerateReportOpen={handleGenerateReportOpen}
            />
            <GeneratePatientReportDialogContainer
              patientId={patientId}
              archived={props.archived}
              open={generateReportOpen}
              handleGenerateReportOpen={handleGenerateReportOpen}
            />
          </Grid>
          <Grid item xs={12}>
            <PatientDetailsContainer
              patientId={patientId}
              archived={props.archived}
              refresh={refreshPatientDetails}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} className={classes.tabContainer}>
            <VitalsTabs
              onChange={handleTabChange}
              tabs={[
                {
                  label: "Vitals",
                  icon: <VitalsIcon />,
                },
                {
                  label: "Alerts",
                  icon: <WarningIcon />,
                  notifications: unresolvedAlerts,
                },
                {
                  label: "Reviews",
                  icon: <ReviewsIcon />,
                },
              ]}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.tabBackground}>
        <Grid container spacing={1} className={classes.gridContainer}>
          {getActiveTab()}
        </Grid>
      </div>
      <div className={classes.background}>
        <Grid container className={classes.gridContainer}>
          {location.state ? (
            location.state.sortedTableData ? (
              <NavigateBetweenPatientsContainer
                onChangePage={onChangePage}
                sortedTableData={location.state.sortedTableData}
              />
            ) : null
          ) : null}
        </Grid>
      </div>
    </>
  );
}
