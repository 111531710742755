import React, { useEffect } from 'react';
import { MeasurementCard } from 'ui-components';
import moment from 'moment';
import { getToken } from '../utils/authenticator';
import { getPatientData } from '../api/patientsApi';
import { Mixpanel } from '../mixpanel';

export default function LatestMeasurementCardContainer(props) {
    const [loading, setLoading] = React.useState(true);
    const [label, setLabel] = React.useState('-');
    const [value, setValue] = React.useState('-');
    const [time, setTime] = React.useState('-');
    const [clicked, setClicked] = React.useState(0);

    const { measurement, patientId, onMeasurementChange, measurementsAdded, left, right } = props;

    const handleChange = () => {
        Mixpanel.track(`Clicked ${measurement.key} measurement card`);
        onMeasurementChange(measurement);
        setClicked(clicked + 1);
    };

    useEffect(() => {
        let isMounted = true; // flag for component mount status
        const getMeasurementFromApi = async () => {
            let result = await getPatientData(patientId, measurement.key, getToken());

            if (isMounted){  // Only run if this component is still mounted
                // The API can return entries with null values, which need to be removed
                result = result.filter(entry => entry[measurement.key] !== null);

                setLabel(measurement.label);
                setValue(result.length > 0 ? measurement.displayWithUnit(result[0][measurement.key]) : '-');
                setTime(result.length > 0 ? moment(result[0].time).fromNow() : '-');

                setLoading(false);
            }
        };
        setLoading(true);
        getMeasurementFromApi();

         return function cleanup() {
            isMounted = false; // function called when the component is unmounted
         };

    }, [measurement, patientId, clicked, measurementsAdded]);

    return (
        <MeasurementCard 
            loading={loading}
            label={label}
            value={value}
            time={time}
            onClick={handleChange}
            left={left}
            right={right}
        />
    )
}
