import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { getPatient } from '../api/patientsApi';
import { getToken } from '../utils/authenticator';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles({
    loading: {
        height: '30px',
        marginLeft: '10px',
        width: '10%',
        marginBottom: '10px'
    }
});

export default function DisplayNameContainer(props) {

    const classes = useStyles();
    const [displayName, setDisplayName] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        let isMounted = true; // flag for component mount status
        setLoading(true);
        const getPatientFromApi = async () => {
            const result = await getPatient(props.patientId, getToken(), props.archived);
            if (isMounted){ // Only run if this component is still mounted
                setDisplayName(result.name || '');
                setLoading(false);
            }
        }

        getPatientFromApi();
        return function cleanup() {
            isMounted = false; // function called when the component is unmounted
        };
    }, [props.patientId, props.archived])

    return(
        loading 
        ? <Skeleton className={classes.loading} />
        : <Typography variant='h4'>{displayName || '-'}</Typography>
    );
}
