import React from 'react';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    button: {
        marginLeft: 4,
    },
});

export default function QuickDateRangeButtonContainer(props) {
    const classes = useStyles();
    const handleClick = () => {
        props.handleClick(moment().subtract(props.amount, props.type), moment());
    };

    return (
        <Button className={classes.button}
            variant="outlined" 
            color="primary"
            size="small"
            onClick={handleClick}
        >
            {`Last ${props.amount} ${props.type}`}
        </Button>
    )
}